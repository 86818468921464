import React, { createContext, useContext, useState, useCallback } from 'react';

const ReloadContext = createContext();

export const useReload = () => useContext(ReloadContext);

export const ReloadProvider = ({ children }) => {
    const [reloadKey, setReloadKey] = useState(0);

    const reload = useCallback(() => {
        setReloadKey(prevKey => prevKey + 1);
    }, []);

    return (
        <ReloadContext.Provider value={{ reload, reloadKey }}>
            {children}
        </ReloadContext.Provider>
    );
};