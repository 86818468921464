import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Layout, Menu } from "antd";
import { CloseCircleOutlined, SmileOutlined, StepForwardOutlined } from "@ant-design/icons";
import Logo from "../../../components/layouts/logo";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getMenu } from "../../../api/okr";

const { Sider } = Layout;

const Sidebar = memo(({ className }) => {
  const collapse = useSelector((state) => state.collapse);
  const location = useLocation();
  let pathName = location.pathName;

  const [listMenu, setListMenu] = useState([]);

  const getListMenu = useCallback(async () => {
    getMenu().then(res => {

      setListMenu(res?.data?.data);
    }).catch(err => {

    });
  }, []);

  useEffect(() => {
    getListMenu();
  }, [])

  const styles = {
    tooltip: {
      color: "#201F1E",
    },
  };

  return (
    <Sider
      className={className}
      collapsed={collapse}
      trigger={null}
      collapsible
      style={{
        background: "#F3F2F1",
      }}
    >
      <Logo />
      <div
        style={{
          padding: "0 24px",
          fontSize: "16px",
          lineHeight: "40px",
          fontWeight: 600,
        }}
      >
        {/* {!collapse ? <Link to="/qlokr">Quản lý OKR</Link> : ""} */}
      </div>
      <Menu
        mode="inline"
        forceSubMenuRender={true}
        style={{
          backgroundColor: "#F3F2F1",
          overflowX: "hidden",
          height: "calc(100vh - 88px)"
        }}
        defaultOpenKeys={['sub1', 'sub2']}
      >
        {listMenu?.map((item, index) => {
          return (
            <>
              {item?.children?.length > 0 ?
                <Menu.SubMenu style={{ color: 'rgba(0, 0, 0, 0.75)' }} key={`sub${index + 1}`} title={item?.name}>
                  {item?.children?.map((item2, index2) => {

                    return <Menu.Item style={{ paddingLeft: 25 }} key={`sub${index + 1}-${index2 + 1}`}>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          display: true,
                          height: 10,
                          width: 10,
                          backgroundColor: '#201F1E',
                          borderRadius: '2px',
                          marginLeft: 10,
                          // marginTop: -4,
                          wordBreak: "break-all",
                        }}>
                        </div>&nbsp;&nbsp;&nbsp;
                        <Link to={`/${item2?.folder}/${item2?.path}`} style={{ ...styles.tooltip }}>
                          {item2?.name}
                        </Link>
                      </div>

                    </Menu.Item>
                  })}
                </Menu.SubMenu> :
                <Menu.Item style={{ paddingLeft: 25 }} key={`sub${index + 1}`}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{
                      display: true,
                      height: 10,
                      width: 10,
                      backgroundColor: '#201F1E',
                      borderRadius: '2px',
                      marginLeft: 2,
                      // marginTop: -4,
                      wordBreak: "break-all",
                    }}>
                    </div>&nbsp;&nbsp;&nbsp;
                    <Link to={`/${item?.folder}/${item?.path}`} style={{ ...styles.tooltip }}>
                      {item?.name}
                    </Link>
                  </div>

                </Menu.Item>
              }
            </>
          );
        })}
      </Menu>

      <div style={{ height: "calc(100vh - 170px)", overflow: "auto" }}>
        {/* menu */}
      </div>
    </Sider>
  );
});

export default styled(Sidebar)`
  .ant-tooltip-content .ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: rgba(0, 0, 0, 0.75);
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  .ant-menu-inline .ant-menu-item {
    height: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;
  }

  .ant-tooltip-content .ant-tooltip {
    color: rgba(0, 0, 0, 0.75);
    background-color: #fff;
  }

  .ant-tooltip-inner a {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  .ant-menu-inline .ant-menu-item:hover {
    background: #e1dfdd;
    color: #000c17 !important;
    a:hover {
      color: #000c17;
    }
  }

  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }

  .ant-menu {
    .ant-menu-item.ant-menu-item-selected {
      background-color: #e1dfdd;
      color: #000c17 !important;
      a {
        color: #000c17 !important;
      }
      .anticon,
      .anticon + span {
        color: #58595b;
      }
    }
  }
  .ant-menu-submenu-title > .ant-menu-title-content {
    font-size: 14px;
    font-weight: 600;
  }

  .ant-menu-inline.ant-menu-root
    .ant-menu-submenu-title
    > .ant-menu-title-content:hover {
    color: black;
  }

  .ant-menu-submenu-arrow:hover {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-menu-light .ant-menu-submenu-title:hover {
    color: black;
    background: #e1dfdd;
  }
  .ant-menu-submenu-selected {
    color: #201f1e;
  }
`;
