const initState = {
  toggleCaretRightStep: {},
  addStep: false,
  currentStep: { stepId: 0, parentId: 0 },
  caretRight: null,
};

const actionProcedure = (actionProcedure = initState, action) => {
  switch (action.type) {
    case "TOGGLE_CARET_RIGHT_STEP":
      return { ...actionProcedure, toggleCaretRightStep: action.toggleCaretRightStep };
    case "TRIGGER_CARET_RIGHT_STEP":
      return {
        ...actionProcedure,
        caretRight: { id: action.id },
      };
    case "TRIGGER_ADD_STEP":
      return { ...actionProcedure, addStep: action.status };
    case "TRIGGER_ADD_SUB_STEP":
      return { ...actionProcedure, addSubStep: action.status };
    case "TRIGGER_CURRENT_STEP":
      return {
        ...actionProcedure,
        currentStep: {
          stepId: action.stepId,
          parentId: action.parentId
        },
      };
    default:
      return actionProcedure;
  }
};
export default actionProcedure;
