import { Col, Row, Spin } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { getDetailStaffReview } from "../../../../../api/okr";
import TableContent from './TableContent';
import PdfFile from './pdfFile';
const PersonalReview = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const getDataStaffReview = useCallback(async () => {
        setLoading(true);
        setTimeout(() => {
            getDetailStaffReview(id).then((response) => {
                setData(response.data.data);
                setTotal(response.data?.meta?.total);
                setLoading(false);
            });
        }, 500)
    }, []);

    useEffect(() => {
        id&&getDataStaffReview()
    }, [getDataStaffReview,id]);

    const onRefreshList = () => {   
        getDataStaffReview();
      }

    return (
        <div>
            <Row gutter={[8, 8]}>
                <Col xs={24}>
                    <TableContent data={data} setData={setData} onRefreshList={onRefreshList} id={id}/>
                </Col>
                <div style={{display:'hidden'}}>
                    <PdfFile data={data} setData={setData} onRefreshList={onRefreshList} id={id}/>
                </div>
            </Row>
        </div>
    );
};

export default PersonalReview;