import { DatePicker, Input, InputNumber, message } from "antd";
import styled from "styled-components";
import React, { memo, useCallback, useState, useRef } from "react";
import { useEffect } from "react";
import { updateReviewTarget } from "../../../../../api/okr";
import _ from 'lodash'
import moment from "moment";
let inputTimer = null;
const Priority = memo(({ className, defaultValue, record, ids, field }) => {
    const [value, setValue] = useState(defaultValue);
    const previousValueRef = useRef('');
    const handleInputChange = (e) => {
        setValue(e);
    };

    const handleSetTime = useCallback(async (e) => {
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            const params = {
                ids: ids,
                id: record?.id,
                due_date: moment(e).format("YYYY-MM-DD")
            }
            updateReviewTarget(params)
                .then(() => {
                    setValue(e)
                })
                .catch(() => {
                    setValue(defaultValue)
                })

        }, 300);
    }, [value])

    const handlePressEnter = useCallback(async () => {
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            const params = {
                ids: ids,
                id: record?.id,
                name: field == 'name' ? value : undefined,
                desire_result: field == 'desire_result' ? value : undefined,
                due_date: field == 'due_date' ? moment(value).format("YYYY-MM-DD") : undefined,
            }
            updateReviewTarget(params)
                .then(() => {
                    setValue(value)
                })
                .catch(() => {
                    setValue(defaultValue)
                })

        }, 300);
    }, [value])

    const handlePaste = useCallback(async (e) => {
        const pastedValue = parseInt((e.clipboardData || window.clipboardData).getData('text').replace(/,/g, ''));
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            const params = {
                ids: ids,
                id: record?.id,
                name: field == 'name' ? pastedValue : undefined,
                desire_result: field == 'desire_result' ? pastedValue : undefined,
                due_date: field == 'due_date' ? moment(pastedValue).format("YYYY-MM-DD") : undefined,
            }
            updateReviewTarget(params)
                .then(() => {
                    setValue(pastedValue)
                })
                .catch(() => {
                    setValue(defaultValue)
                })
        }, 300);
    }, [])


    const handleBlur = useCallback(async () => {
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            if (value !== defaultValue) {
                const params = {
                    ids: ids,
                    id: record?.id,
                    name: field == 'name' ? value : undefined,
                    desire_result: field == 'desire_result' ? value : undefined,
                    due_date: field == 'due_date' ? moment(value).format("YYYY-MM-DD") : undefined,
                }
                updateReviewTarget(params)
                    .then(() => {
                        setValue(value)
                    })
                    .catch(() => {
                        setValue(defaultValue)
                    })
            }
        }, 300);
    }, [value])

    React.useEffect(() => {
        previousValueRef.current = defaultValue;
    }, [defaultValue]);

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue]);

    return (
        <div className={className}>
            {field !== "due_date" ?
                <Input
                    value={value}
                    bordered={false}
                    style={{ width: '100%', color: '#000' }}
                    onChange={(e) => handleInputChange(e?.target?.value)}
                    onPressEnter={handlePressEnter}
                    onPaste={handlePaste}
                    onBlur={handleBlur}
                />
                : <DatePicker
                    // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={value !== null ? moment(value) : ""}
                    bordered={false}
                    style={{ width: '100%', color: '#000' }}
                    onChange={handleSetTime}
                    format={"DD-MM-YYYY"}
                    onPressEnter={handlePressEnter}
                    onPaste={handlePaste}
                    onBlur={handleBlur}
                />}
        </div>
    );
});
export default styled(Priority)`
.ant-input-number-handler-wrap { 
  display:none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 4px;
  text-align: left;
  background-color: transparent;
  border: 0; 
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
`
