import React from 'react';
import { Table, Pagination, Row } from 'antd';
import 'antd/dist/antd.css';
import { EditOutlined } from '@ant-design/icons';
import styled from "styled-components";
import PropTypes from "prop-types";
import icon_edit from "../../../../../assets/images/icons/icon_edit.png";
const MyTable = ({ className, data, onEdit, params, setParams, setTotal, total }) => {
    console.log('total', total)
    const columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (value, row, index) => {
                const stringIndex = `${((params.page - 1) * params.size + index)}`;
                return (
                    <h5 style={{ textAlign: 'center' }}>{params.page === 1 ? index + 1 : parseInt(stringIndex) + 1}</h5>
                );
            },
        },
        {
            title: 'Họ tên',
            dataIndex: 'staff',
            key: 'staff',
            width: '30%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>{text?.name}</div>
                )
            },
        },
        {
            title: 'Mã NV',
            dataIndex: 'staff',
            key: 'staff',
            width: '12%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>{text?.code}</div>
                )
            },
        },
        {
            title: 'Giao chỉ tiêu',
            dataIndex: 'give_criteria',
            key: 'give_criteria',
            width: '15%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center',color:text?.color }}>{text?.name}</div>
                )
            }
        },
        {
            title: 'Cá nhân đánh giá',
            dataIndex: 'employee_review',
            key: 'employee_review',
            width: '15%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center',color:text?.color }}>{text?.name}</div>
                )
            }
        },
        {
            title: 'Quản lý đánh giá',
            dataIndex: 'manager_review',
            key: 'manager_review',
            width: '12%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center',color:text?.color }}>{text?.name}</div>
                )
            }
        },
        {
            title: 'Chi tiết',
            key: 'edit',
            width: '10%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <a style={{ color: '#0078D4' }} href={`/okr/report_review_detail?id=${record?.id}`} target="_blank">Chi tiết</a>
                    </div>
                )
            },

        },
    ];


    const renderContent = () => {
        return (
            <Row justify="end" style={{ marginBottom: 5, marginTop: 5 }}>
                <Pagination
                    onShowSizeChange={(current, size) => {
                        setParams((prevState) => {
                            let nextState = { ...prevState };
                            nextState.page = 1;
                            nextState.size = size;
                            return nextState;
                        });
                    }}
                    onChange={(page, pageSize) => {
                        setParams((prevState) => {
                            let nextState = { ...prevState };
                            nextState.page = page;
                            return nextState;
                        });
                    }}
                    total={total}
                    current={params.page}
                    pageSize={params.size}
                    showSizeChanger
                />
            </Row>
        );
    };



    const expandIcon = () => {
        return null;
    };

    return (
        <div className={className}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                defaultExpandAllRows={true}
                size="small"
                rowKey={'id'}
                expandable={{
                    expandRowByClick: false,
                    expandIcon,
                    // expandedRowRender,
                    expandIconColumnIndex: -1
                }}
                bordered
                scroll={{ x: "100vh", y: "calc(100vh - 300px)" }}
            />
            {renderContent()}
        </div>
    );
};

MyTable.propTypes = {
    className: PropTypes.any,
};
export default styled(MyTable)`
.custom-table-container {
}
.ant-table-thead > tr > th {
  background-color: #1F78B4;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.ant-table-tbody > tr.ant-table-row-level-0 > td {
  font-weight:450;
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    padding: 4px 4px;
}
`;

