const initState = {
  toggleCaretRight: {},
  addTask: false,
  addSubTask: false,
  addSection: false,
  currentTask: { taskId: 0, sectionId: 0, parentId: 0 },
  filter: null,
  activeTask: 0,
  sortColumn: null,
  tabActive: 1,
  isDrawerRepeat: false,
  multiTaskSelected: [],
  shiftKey: false,
  caretRight: null,
  visibleRemoveProject: false,
};

const actionProject = (actionProject = initState, action) => {
  switch (action.type) {
    case "TRIGGER_CARET_RIGHT":
      return {
        ...actionProject,
        caretRight: { id: action.id, type: action.typeId },
      };
    case "TOGGLE_CARET_RIGHT":
      return { ...actionProject, toggleCaretRight: action.toggleCaretRight };
    case "TRIGGER_ACTIVE_TASK":
      return { ...actionProject, activeTask: action.activeTask };
    case "TRIGGER_ADD_TASK":
      return { ...actionProject, addTask: action.status };
    case "TRIGGER_ADD_SUB_TASK":
      return { ...actionProject, addSubTask: action.status };
    case "TRIGGER_ADD_SECTION":
      return { ...actionProject, addSection: action.status };
    case "TRIGGER_SORT_COLUMN":
      return { ...actionProject, sortColumn: action.sortColumn };
    case "TRIGGER_SET_DRAWER_REPEAT":
      return { ...actionProject, isDrawerRepeat: action.isDrawer };
    case "TRIGGER_SET_MULTI_TASK_SELETED":
      return { ...actionProject, multiTaskSelected: action.multiTaskSelected };
    case "SET_SHIFT_KEY":
      return { ...actionProject, shiftKey: action.status };
    case "TRIGGER_CURRENT_TASK":
      return {
        ...actionProject,
        currentTask: {
          taskId: action.taskId,
          parentId: action.parentId,
          sectionId: action.sectionId,
        },
      };
    case "SET_FILTER_PROJECT":
      return {
        ...actionProject,
        filter: action.filter,
      };
    case "SET_TAB_ACTIVE":
      return {
        ...actionProject,
        tabActive: action.tabActive,
      };
    case "SET_VISIBLE_REMOVE_PROJECT":
      return {
        ...actionProject,
        visibleRemoveProject: action.visible,
      };
    default:
      return actionProject;
  }
};
export default actionProject;
