import Home from "./Home"
import KeyResult from "./KeyResult";
import Objective from "./Objective";
import Criteria from "./PersonalReview/Criteria";
import ReportReview from "./PersonalReview/ReportReview";
import Detail from "./PersonalReview/ReportReview/Detail";

const routes = [
    {
        path: "/okr",
        name: "Quản lý OKR",
        component: Home,
        exact: true
    },
    {
        path: "/okr/objective",
        name: "Danh mục Objective",
        component: Objective,
        exact: false
    },
    {
        path: "/okr/key-result",
        name: "Danh mục Key Result",
        component: KeyResult,
        exact: false
    },
    // {
    //     path: "/qlokr/procedures/:id",
    //     name: "Chi tiết quy trình",
    //     component: Procedure,
    //     exact: false
    // }
    {
        path: "/okr/criteria_review",
        name: "Đánh giá cá nhân",
        component: Criteria,
        exact: true
    },
    {
        path: "/okr/report_review",
        name: "Báo cáo đánh giá",
        component: ReportReview,
        exact: true
    },
    {
        path: "/okr/report_review_detail",
        name: "Chi tiết báo cáo đánh giá",
        component: Detail,
        exact: true
    },

]

export default routes;