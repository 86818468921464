import axios from "../../plugin/axios";

export const listProcedures = () => {
    return axios.get("/api/workflow/procedure");
};

export const createProcedures = (name, description) => {
    return axios.post("/api/workflow/procedure", {name: name, description: description});
};

export const detailProcedures = (procedureId) => {
    return axios.get(`/api/workflow/procedure/${procedureId}`);
};

export const renameProcedures = (procedureId, name) => {
    return axios.post(`/api/workflow/procedure/${procedureId}/rename`, {name: name});
};

export const describleProcedures = (procedureId, description) => {
    return axios.post(`/api/workflow/procedure/${procedureId}/describle`, {description: description});
};

export const publicProcedure = (procedureId) => {
    return axios.post(`/api/workflow/procedure/${procedureId}/public`);
}

export const revokeProcedure = (procedureId) => {
    return axios.post(`/api/workflow/procedure/${procedureId}/revoke`);
}