import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import "./style.scss"
import "./indexFlex.css"
import LayoutOKRManagement from './views/OKRManagement/layouts'
moment.locale('vi');


const Dashboard = React.lazy(() => import("../src/views/Dasboard"));
const LayoutDocManagement = React.lazy(() =>
  import("../src/views/DocumentManagement/layouts")
);

const LayoutWorkManagement = React.lazy(() =>
  import("../src/views/WorkManagement/layouts")
);

const LayoutTimekeeping = React.lazy(() =>
  import("../src/views/TimeKeeping/layouts")
);

const LayoutWorkflow = React.lazy(() =>
  import("./views/Workflow/layouts")
);


const Login = React.lazy(() => import("./views/Login"));

const Page403 = React.lazy(() => import("./views/403/page403"));
const Page404 = React.lazy(() => import("./views/404/page404"));
const Page500 = React.lazy(() => import("./views/500/page500"));

const App = (props) => {

  return (
    <BrowserRouter>
      <React.Suspense fallback={false}>
        <Switch>
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/403"
            name="Page 403"
            render={(props) => <Page403 {...props} />}
          />
          <Route path="/" exact>
            {props.isLogged ? <Dashboard /> : <Redirect to="/login" />}
          </Route>
          <Route path="/qltl">
            {props.isLogged ? (
              <LayoutDocManagement />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/qlcv">
            {props.isLogged ? (
              <LayoutWorkManagement />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/okr">
            {props.isLogged ? (
              <LayoutOKRManagement />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/qlcc">
            {props.isLogged ? (
              <LayoutTimekeeping />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/qlqt">
            {props.isLogged ? (
              <LayoutWorkflow />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/login">
            {props.isLogged ? <Redirect to="/" /> : <Login />}
          </Route>
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};
const mapStateToProps = (state) => {
  return {
    isCollapse: state.collapse,
    isLogged: state.auth.isLogged,
  };
};

export default connect(mapStateToProps, null)(App);
