import React, { useEffect } from 'react';
import DefineTable from '../../../../components/DefineTable';
import { Button, Table, Row, Pagination } from 'antd';
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import moment from 'moment';
import { NodeExpandOutlined } from '@ant-design/icons'
import { formatNumber } from '../../../../helper/helper';

const List = ({
    className,
    data,
    onEdit,
    objective,
    departments,
    total,
    setTotal,
    params,
    setParams }) => {

    const renderTreeNodes = (departments) =>
        departments?.map((item) => {
            if (item.children && item.children.length) {
                return (
                    <div title={item.name} key={item.id} value={item.id} >
                        {renderTreeNodes(item.children)}
                    </div>
                );
            }
            return <div title={item.name} key={item.id} value={item.id} />;
        });

    const columns = [
        {
            title: "STT",
            dataIndex: 'id',
            width: "5%",
            // fixed: 'left',
            render: (text, record, index) => {
                const stringIndex = `${((params.page - 1) * params.size + index)}`;
                return (
                    <div style={{ textAlign: 'center' }}>{params.page === 1 ? index + 1 : parseInt(stringIndex) + 1}</div>
                );
            },
        },
        {
            title: "Key Result",
            fixed: 'left',
            dataIndex: 'name',
            width: "30%",
            render: (text, record, index) => {
                return <div style={{ color: '#1F78B4', cursor: 'pointer', textAlign: 'justify' }} onClick={e => { onEdit(record) }} type='link'>{text}</div>
            }
        },
        {
            title: "Mô tả",
            dataIndex: 'description',
            width: "35%",
            render: (text, record, index) => {
                return <div style={{ textAlign: 'justify' }}>{text}</div>
            }
        },
        {
            title: "Mục tiêu",
            dataIndex: 'target',
            width: "12%",
            render: (text, record, index) => {
                if (record?.unit?.value === 2) {
                    return record?.target !== null && record?.target + " %";
                } else if (record?.unit?.value === 3) {
                    return record?.target && <div style={{ textAlign: 'justify' }}>{record?.target}</div>
                } else {
                    return record?.target && formatNumber(record?.target);
                }
            }
        },
        {
            title: "Thời gian hoàn thành",
            dataIndex: 'due_date',
            width: "12%",
            render: (text, record, index) => {
                return record?.due_date !== '' ? moment(record?.due_date).format('DD/MM/YYYY') : '';
            }
        },
        {
            title: "Phòng ban",
            dataIndex: 'phong_ban',
            width: "30%",
            render: (text, record, index) => {
                if (record?.department?.phb_parent_id === 0) {
                    return record?.department?.phb_name;
                } else {

                }
            }
        },
        {
            title: "Objective",
            dataIndex: 'objective',
            width: "30%",
            render: (text, record, index) => {

                if (record?.objective?.parent_id === null) {
                    return record?.objective?.name;
                } else {
                    return (
                        <>
                            {objective?.map(item => {
                                return (
                                    <>
                                        {item?.id === record?.objective?.parent_id &&
                                            <>
                                                <div>{item?.name}</div>
                                                <div style={{ paddingLeft: 20 }}><NodeExpandOutlined />&nbsp;&nbsp;{record?.objective?.name}</div>
                                            </>

                                        }

                                    </>);
                            })}
                        </>
                    );
                }

            }
        }
    ];

    const renderContent = () => {
        return (
            <Row justify="end" style={{ marginBottom: 5, marginTop: 5 }}>
                <Pagination
                    onShowSizeChange={(current, size) => {
                        setParams((prevState) => {
                            let nextState = { ...prevState };
                            nextState.page = 1;
                            nextState.size = size;
                            return nextState;
                        });
                    }}
                    onChange={(page, pageSize) => {
                        setParams((prevState) => {
                            let nextState = { ...prevState };
                            nextState.page = page;
                            return nextState;
                        });
                    }}
                    total={total}
                    current={params.page}
                    pageSize={params.size}
                    showSizeChanger
                />
            </Row>
        );
    };

    return (
        <div className={className}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                defaultExpandAllRows={true}
                size="small"
                rowKey={'id'}
                scroll={{ y: "calc(100vh - 300px)" }}
                bordered
            />
            {renderContent()}
        </div>
    );
};

List.propTypes = {
    className: PropTypes.any,
};
export default styled(List)`
.custom-table-container {
}
.ant-table-thead > tr > th {
  background-color: #1F78B4;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
}

.ant-table-tbody > tr.ant-table-row-level-0 > td {
  background-color: #fff;
//   font-weight:500;
  font-size: 15px;
}

.ant-table-tbody > tr.ant-table-row-level-1 > td {
  background-color: #ffff;
  font-weight:450;
  font-size: 14px;
  padding-left:15px;
}
`;