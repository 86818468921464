const initalState = {
    status: false,
    taskId: '',
    location: {}
  };

const toggleDrawer = (drawer = initalState, action) => {
    switch (action.type) {
        case 'TOGGLE_DRAWER':
            return action.drawer;
        case 'SET_LOCATION_TASK': 
            return {
                ...drawer,
                location: action.location
            }
        default:
            return drawer
    }
}
export default toggleDrawer;