import { Input, message, Modal, Form } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { createProjectGroup } from "../../../../api/ProjectGroup";
import { setMenuWork } from "../../../../stores/actions";

const ModalAddProjectGroup = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const menuWork = useSelector(state => state.menuWork)
  const history = useHistory();

  const handleOk = async () => {
    form.submit();
  };

  const onFinish = (value) => {
    createProjectGroup(value.name)
      .then((response) => {
        message.success("Tạo nhóm dự án thành công");
        form.resetFields();
        props.setVisible(false);
        let project = {
          id: response.data.data.id,
          name: response.data.data.name,
        };
        menuWork.project_groups.push(project);
        dispatch(setMenuWork({ ...menuWork, project_groups: menuWork.project_groups}));
        return response;
      })
      .then((response) => history.push(`/qlcv/group/${response.data.data.id}/gantt`))
      .catch((err) => {
        message.error("Tạo nhóm dự án không thành công");
      });
  };

  const handleCancel = () => {
    form.resetFields();
    props.setVisible(false);
  };

  return (
    <Modal
      title="Tạo nhóm dự án"
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Huỷ"
      okText="Tạo mới"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Tên nhóm dự án"
          rules={[
            {
              required: true,
              message: "Tên nhóm dự án không được để trống",
            }
          ]}
          hasFeedback
        >
          <Input placeholder="Ví dụ: TECH 2021" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddProjectGroup;
