const initState = { index: 0, parentId: 0, sectionId: 0 };
const currentTask = (currentTask = initState, action) => {
  switch (action.type) {
    case "SET_CURRENT_TASK":
      return action.currentTask;
    case "SET_CURRENT_TASK_DEFAULT":
      return initState;
    default:
      return currentTask;
  }
};
export default currentTask;
