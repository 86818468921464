const initAuth = {
  isLogged: false,
  user: null,
};

const auth = (auth = initAuth, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...initAuth, isLogged: true, user: action.auth };
    case "LOGOUT":
      return { ...initAuth, isLogged: false };
    default:
      return auth;
  }
};
export default auth;
