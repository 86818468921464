const step = (step = null, action) => {
    switch (action.type) {
      case "UPDATED_STEP":
        return { params: action.params, action: action.action };
      case "DELETED_STEP":
        return { params: action.params, action: action.action };
      case "CREATED_STEP":
        return { params: action.params, action: action.action };
      default:
        return step;
    }
  };
  export default step;
  