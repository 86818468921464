import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import List from './List';
import { useDispatch, useSelector } from 'react-redux';
import ModalUpdateObjective from './ModalUpdateObjective';
import { getObjective } from '../../../api/okr';
import { setObjective } from '../../../stores/actions/ork/objective';
import ModalCreateObjective from './ModalCreateObjective';
import axiosInstance from '../../../plugin/axios';

const Objective = () => {
    const objective = useSelector((state) => state?.objective);
    const [data, setData] = useState(objective);
    const [isShowModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);

    const onEdit = useCallback(async (record) => {
        setShowModal(true)
        setItemSelected(record)
    }, [])

    const [dataDepartments, setDataDepartments] = useState([]);
    const getDataDepartments = useCallback(async () => {
        return await axiosInstance
            .get(`/api/tasking/get-departments`)
            .then((res) => {
                setDataDepartments(res.data.data);
                // dispatch(setDepartments(res.data.data));
            })
            .catch((err) => {
            })
    }, []);

    useEffect(() => {
        getDataDepartments()
    }, []);

    useEffect(() => {
        setData(objective)
    }, [objective]);

    return (
        <div>
            <Row>
                <Col xs={24}>
                    {data?.length > 0 ? <List
                        data={data}
                        onEdit={onEdit}
                        dataDepartments={dataDepartments}
                    /> : null}
                </Col>
            </Row>
            {itemSelected ? (<ModalCreateObjective
                visible={isShowModal}
                setVisible={setShowModal}
                itemSelected={itemSelected}
                setItemSelected={setItemSelected}
            />) : null}
        </div>
    );
};

export default Objective;