import React, { useState, useEffect, useCallback,useRef } from "react";
import {
  Dropdown,
  Layout,
  Badge,
  Avatar,
  Row,
  Col,
  Upload,
  Button,
  message,
} from "antd";
// import account from "./account";
import createProject from "../../assets/images/icons/create_project.svg";
import createGroupProject from "../../assets/images/icons/icon_add_group_project.svg";
import SearchFrom from "../search";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  UploadOutlined,
  FolderAddOutlined,
  PlusOutlined
} from "@ant-design/icons";
import ShareAdmin from "../../views/DocumentManagement/ShareAdmin";
import { actionLogin, actionLogout, setMenuRedux } from "../../stores/actions";

import { useHistory, useLocation } from "react-router-dom";
import PopupFolder from "../popup/popupFolder";
import { addFolder } from "../../api/foldes";
import { logout as apiLogout, getUser } from "../../api/auth";
import WorkSearchForm from "../../views/WorkManagement/Components/Search/WorkSearchForm";
import ModalAddProject from "../../views/WorkManagement/Components/ModalAddProject";
import ModalAddProjectGroup from "../../views/WorkManagement/Components/ModalAddProjectGroup";
import { importDataTimesheet } from "../../api/TimeKeeping/TimesheetSummary";
import WorkflowSearchFrom from "../../views/Workflow/Components/Search";
import ModalCreateProcedure from "../../views/Workflow/Procedures/ModalCreateProcedure";
import ModalCreateKeyResult from "../../views/OKRManagement/KeyResult/ModalCreateKeyResult";
import ModalCreateObjective from "../../views/OKRManagement/Objective/ModalCreateObjective";
import ModelCreateCriteria from "../../views/OKRManagement/PersonalReview/Criteria/ModelCreateCriteria";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { mySharedRef  } from '../../views/OKRManagement/PersonalReview/ReportReview/Detail/myRefs';
import ReactToPrint from "react-to-print";


let time;

const Header = (props) => {
  const componentRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [visibleProjectGroup, setVisibleProjectGroup] = useState(false);
  const [visibleCreateProcedure, setVisibleCreateProcedure] = useState(false);
  const [loadingImportTimeSheet, setLoadingImportTimeSheet] = useState(false);
  const [visibleCreateObjective, setVisibleCreateObjective] = useState(false);
  const [visibleCreateKeyResult, setVisibleCreateKeyResult] = useState(false);
  const [visibleCreateCriteria, setVisibleCreateCriteria] = useState(false);
  const [objStyle, setObjStyle] = useState({
    unset: "auto",
    height: "calc(100vh - 200px)",
    box_shadow: "inset -1px 0px 0px #f1f1f5",
    border: "none",
  });

  function removeScroll() {
    const scrollableElements = document.querySelectorAll('.scrollable');
    scrollableElements.forEach(element => {
        element.style.height = 'auto';
        element.style.overflow = 'visible';
    });
}

  const filePdf = () => {
    removeScroll();
    html2canvas(mySharedRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: 'portrait',
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save("danh-gia.pdf");
    });
  };

  const [url, setUrl] = useState(undefined);

  const logout = async () => {
    await apiLogout().then(() => dispatch(actionLogout()));
    localStorage.setItem("arrHeaderList", null);
    history.push("/login");
  };

  const getAuth = useCallback(async () => {
    await getUser().then((response) => {
      dispatch(actionLogin({ ...response.data, token: auth.user.token }));
    });
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    setUrl(location.pathname)
  }, [location.pathname]);

  useEffect(() => {
    getAuth();
  }, [getAuth]);
  const MenuAccount = (
    <Menu>
      <Menu.Item key={1}>
        <span>
          <UserOutlined style={{ marginRight: "10px" }} />
          Tài khoản
        </span>
      </Menu.Item>
      {auth.user?.isAdmin === 1 ? (
        <Menu.Item key={2}>
          <ShareAdmin />
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item key={3} onClick={logout}>
        <span>
          <LogoutOutlined style={{ marginRight: "10px" }} />
          Đăng xuất
        </span>
      </Menu.Item>
    </Menu>
  );

  function beforeUpload(file, fileList) {
    let stateFiles = history.location.state?.files ?? [];
    history.push("/qltl/upload-file", {
      files: [...new Set(stateFiles.concat(fileList))],
    });
  }

  const importTimeSheetData = async (file) => {
    //append form data
    const formParam = new FormData();
    formParam.append("file", new File([file], file.name, {
      type: file.type,
    }));

    setLoadingImportTimeSheet(true)
    await importDataTimesheet(formParam).then(() => {
      setLoadingImportTimeSheet(false)
      message.success("Import dữ liệu chấm công thành công!");
    }).catch(() => {
      setLoadingImportTimeSheet(false)
      message.success("Import dữ liệu chấm công không thành công!")
    }
    )
  }

  const handleAddFolder = async (input, folder_id, description) => {
    await addFolder({
      name: input,
      folder_id: folder_id,
      description: description,
    }).then((response) => {
      message.success(response.data.message);
      dispatch(setMenuRedux(response.data.data));
      if (folder_id > 0) {
        history.push(`/qltl/${folder_id}/tai-lieu-` + Math.random());
      } else {
        history.push(`/qltl`);
      }
    });
  };

  return (
    <Layout.Header
      className="site-layout-background header"
      style={{
        padding: 0,
        background: "#FFC20E",
        height: "48px",
        lineHeight: "48px",
      }}
    >
      <Row>
        <Col
          span={16}
          style={{
            height: "48px",
            // paddingLeft: "40px",
            paddingLeft: "12px",
            alignItems: "center",
            display: "flex",
          }}
        >
          {props.docMamager && (
            <>
              <SearchFrom />
              <Upload
                beforeUpload={beforeUpload}
                showUploadList={false}
                multiple={true}
              >
                <Button
                  style={{
                    marginLeft: "15px",
                    background: "#0078D4",
                    border: "none",
                  }}
                  type="primary"
                  icon={<UploadOutlined />}
                >
                  Tải lên
                </Button>
              </Upload>

              {auth.user?.isAdmin === 1 && (
                <Button
                  style={{
                    marginLeft: "15px",
                    background: "#008D47",
                    border: "none",
                  }}
                  type="primary"
                  icon={<FolderAddOutlined />}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  Tạo thư mục
                </Button>
              )}
              <PopupFolder
                placeholder="Nhập tên thư mục của bạn"
                title="Thư mục"
                btnText="Tạo"
                visible={visible}
                setVisible={setVisible}
                handleAddFolder={handleAddFolder}
                treeSelect
                description
              />
            </>
          )}

          {props.WorkManager && (
            <>
              <WorkSearchForm />
              <Button
                style={{
                  marginLeft: "15px",
                  border: "none",
                }}
                type="primary"
                onClick={() => setVisible(true)}
              >
                <img
                  src={createProject}
                  style={{ paddingRight: "5px" }}
                  alt="p"
                />
                Tạo dự án
              </Button>
              <Button
                style={{
                  marginLeft: "15px",
                  border: "none",
                  background: "#008D47"
                }}
                type="primary"
                onClick={() => setVisibleProjectGroup(true)}
              >
                <img
                  src={createGroupProject}
                  style={{ paddingRight: "5px" }}
                  alt="p"
                />
                Tạo nhóm
              </Button>
              <ModalAddProject setVisible={setVisible} visible={visible} />
              <ModalAddProjectGroup setVisible={setVisibleProjectGroup} visible={visibleProjectGroup} />
            </>
          )}
          {
            props.TimeKeeping && (
              <>
                <Upload
                  beforeUpload={importTimeSheetData}
                  showUploadList={false}
                  multiple={false}
                >
                  <Button
                    style={{
                      background: "rgb(52, 157, 14)",
                      border: "none",
                    }}
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={loadingImportTimeSheet}
                  >
                    Import dữ liệu chấm công
                  </Button>
                </Upload>
              </>
            )
          }
          {
            props.workflow && (
              <>
                <WorkflowSearchFrom />
                <Button
                  style={{ marginLeft: "15px" }}
                  type="primary"
                  icon={<FolderAddOutlined />}
                  onClick={() => setVisibleCreateProcedure(true)}
                >
                  Thêm quy trình
                </Button>
                <Button
                  style={{ marginLeft: "15px" }}
                  type="primary"
                  icon={<FolderAddOutlined />}
                >
                  Thêm yêu cầu
                </Button>
                <ModalCreateProcedure visible={visibleCreateProcedure} setVisible={setVisibleCreateProcedure} />
              </>
            )
          }
          {
            props.objective && (
              <>
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                    borderRadius: 3,
                    background: '#0078D4',
                    color: '#fff'
                  }}
                  icon={<PlusOutlined />}
                  onClick={() => setVisibleCreateObjective(true)}
                >Thêm Objective</Button>
                <ModalCreateObjective
                  visible={visibleCreateObjective}
                  setVisible={setVisibleCreateObjective}
                />
              </>
            )
          }


          {props.criteria && (
            <>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  borderRadius: 3,
                  background: '#0078D4',
                  color: '#fff'
                }}
                icon={<PlusOutlined />}
                onClick={() => setVisibleCreateCriteria(true)}
              >Thêm tiêu chí đánh giá</Button>
              <ModelCreateCriteria visible={visibleCreateCriteria} setVisible={setVisibleCreateCriteria} />
            </>
          )
          }
          {props.detailReport && (
            <>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  borderRadius: 3,
                  background: '#0078D4',
                  color: '#fff'
                }}
               
              ><ReactToPrint
              trigger={() => <button style={{ background: '#0078D4', border: 'none', cursor: 'pointer' }}>In file</button>}
              content={() => mySharedRef.current}
            /></Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  borderRadius: 3,
                  background: 'rgb(54 143 40)',
                  color: '#fff'
                }}
                onClick={() => {
                  setObjStyle({
                    unset: "unset",
                    height: "unset",
                    box_shadow: "unset",
                    border: "1px solid #ccc",
                  });
                  clearTimeout(time);
                  time = setTimeout(() => {
                    filePdf()
                    setObjStyle({
                      unset: "auto",
                      height: "100vh",
                      box_shadow: "inset -1px 0px 0px #f1f1f5",
                      border: "none",
                    });
                  }, 300);
          
                }}
              >Xuất PDF</Button>
            </>
          )
          }
          {
            props.keyResult && (
              <>
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                    borderRadius: 3,
                    background: '#0078D4',
                    color: '#fff'
                  }}
                  icon={<PlusOutlined />}
                  onClick={() => setVisibleCreateKeyResult(true)}
                >Thêm Key Result</Button>
                <ModalCreateKeyResult
                  visible={visibleCreateKeyResult}
                  setVisible={setVisibleCreateKeyResult}
                />
              </>
            )
          }
        </Col>
        <Col
          span={8}
          style={{
            height: "48px",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "20px",
          }}
        >
          <Row gutter={16} align="bottom" ref={componentRef}>
            <Col>
              <Row align="middle" gutter={16}></Row>
            </Col>
            <Col>
              <Dropdown overlay={MenuAccount} placement="bottomCenter" >
                <div style={{ cursor: 'pointer' }}>
                  <span style={{ marginRight: "5px" }}>Chào, <span style={{ fontWeight: 500 }}>{auth.user.name}</span></span>
                  <Badge dot style={{ background: "green" }}>
                    {auth.user.avatar ? (
                      <Avatar src={auth.user.avatar} />
                    ) : (
                      <Avatar
                        style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                      >
                        {auth.user.name.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                  </Badge>
                </div>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
