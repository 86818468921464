import { Col, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import List from './List';
import Filter from './Filter';
import { getKeyResult, getObjective } from '../../../api/okr';
import { useDispatch, useSelector } from 'react-redux';
import { setKeyResult } from '../../../stores/actions/ork/keyResult';
import ModalCreateKeyResult from './ModalCreateKeyResult';
import axiosInstance from '../../../plugin/axios';

const KeyResult = () => {

    const dispatch = useDispatch();
    const keyResult = useSelector(state => state.keyResult);
    const [dataKeyResult, setDataKeyResult] = useState(keyResult)
    const objective = useSelector(state => state.objective);
    const departments = useSelector(state => state.departments);

    const [params, setParams] = useState({
        page: 1,
        size: 20,
        name: undefined,
        objective_id: undefined,
        department_id: undefined
    });

    useEffect(() => {
        setParams({
            page: 1,
            size: 20,
            name: undefined,
            objective_id: undefined,
            department_id: undefined
        });
    }, [keyResult]);

    const [loading, setLoading] = useState(false);
    const [isShowModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
    const [total, setTotal] = useState(0);

    const [dataObjective, setDataObjective] = useState([]);
    const getDataObjective = useCallback(async () => {
        getObjective().then((response) => {
            setDataObjective(response.data.data);
        });
    }, []);

    const [dataDepartments, setDataDepartments] = useState([]);

    const getDataDepartments = useCallback(async () => {
        return await axiosInstance
            .get(`/api/tasking/get-departments`)
            .then((res) => {
                setDataDepartments(res.data.data);
            })
            .catch((err) => {
            })
    }, []);

    useEffect(() => {
        getDataObjective();
        getDataDepartments()
    }, []);

    const onEdit = useCallback(async (record) => {
        setShowModal(true)
        setItemSelected(record)
    }, [])

    const getDataKeyResult = useCallback(async () => {
        setLoading(true);
        getKeyResult(params).then(res => {
            setDataKeyResult(res?.data?.data);
            setTotal(res?.data?.meta?.total);
            setLoading(false);
        });
    }, [params]);

    useEffect(() => {
        setParams(params);
    }, [params]);


    useEffect(() => {
        getDataKeyResult();
    }, [getDataKeyResult])
    useEffect(() => {
        setDataKeyResult(keyResult);
    }, [keyResult])

    return (
        <div>
            <Row>
                <Col xs={24}>
                    <Filter
                        params={params}
                        setParams={setParams}
                        objective={dataObjective}
                        departments={dataDepartments}
                    />
                </Col>
                <Col style={{ marginTop: 20 }} xs={24}>
                    <Spin spinning={loading}>
                        <List
                            data={dataKeyResult}
                            onEdit={onEdit}
                            objective={dataObjective}
                            departments={dataDepartments}
                            total={total}
                            setTotal={setTotal}
                            params={params}
                            setParams={setParams}
                        />
                    </Spin>

                </Col>
            </Row>
            {itemSelected ? (<ModalCreateKeyResult
                visible={isShowModal}
                setVisible={setShowModal}
                itemSelected={itemSelected}
                setItemSelected={setItemSelected}
            // onRefreshList={onRefreshList}
            />) : null}
        </div>
    );
};

export default KeyResult;