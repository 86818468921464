const initState = { visible: false, tabKey: 1 };
const overview = (overview = initState, action) => {
  switch (action.type) {
    case "SET_OVERVIEW":
      return { visible: action.isVisible, tabKey: action.tabKey };
    case "SET_DEFAULT_OVERVIEW":
      return initState;
    default:
      return overview;
  }
};
export default overview;
