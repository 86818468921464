
export const setDataMyTask = (project) => ({
    type: "SET_DATA_MY_TASK",
    project,
});

export const setMarkCompleteMyTask = (task) => ({
    type: "SET_MARK_COMPLETE_MY_TASK",
    task,
  });
  
  export const setAssigneeMyTask = (task) => ({
    type: "SET_ASSIGNEE_MY_TASK",
    task,
  });
  
  export const setTimeMyTask = (task) => ({
    type: "SET_TIME_MY_TASK",
    task,
  });
  
  export const setRenameMyTask = (task) => ({
    type: "SET_RENAME_MY_TASK",
    task,
  });
  
  export const createNewMyTask = (task) => ({
    type: "CREATE_MY_TASK",
    task,
  });
  
  export const setProgressMyTask = (task) => ({
    type: "SET_PROGRESS_MY_TASK",
    task,
  });
  
  export const setRemoveMyTask = (task) => ({
    type: "REMOVE_MY_TASK",
    task,
  });
  
  export const setDescriptionMyTask = (task) => ({
    type: "SET_DESCRIPTION_MY_TASK",
    task,
  });