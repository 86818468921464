import { Col, Row, Spin } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import List from './List';
import { useDispatch, useSelector } from "react-redux";
import ModelCreateCriteria from './ModelCreateCriteria';
import Filter from './Filter';
import { Switch, Route } from "react-router-dom";
import moment from "moment";
import { getStaffReview } from "../../../../api/okr";
import Detail from './Detail';
import { size } from 'lodash';
const PersonalReview = () => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);
    const [isShowModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({
        month: moment(),
        page: 1,
        size: 20,
        staff_info: ''
    });

    const getDataReportReview = useCallback(async () => {
        const param = {
            month: moment(params?.month).format("YYYY-MM"),
            page: params?.page,
            size: params?.size,
            staff_info: params?.staff_info
        }
        setLoading(true);
        setData(null)
        setTimeout(() => {
            getStaffReview(param).then((response) => {
                setData(response.data.data);
                setTotal(response.data?.meta?.total);
                setLoading(false);
            });
        }, 500)
    }, [params]);

    useEffect(() => {
        getDataReportReview()
    }, [getDataReportReview]);


    const onEdit = useCallback(async (record) => {
        setShowModal(true)
        setItemSelected(record)
    }, [])
    return (
        <div>
            <Row gutter={[0, 8]}>
                <Col xs={24}>
                    <Filter params={params} setParams={setParams} />
                </Col>
                <Col xs={24}>
                    <Spin spinning={loading} >
                        <List data={data} onEdit={onEdit} params={params} setParams={setParams} total={total} setTotal={setTotal} />
                    </Spin>
                </Col>
            </Row>
            {itemSelected ? (<ModelCreateCriteria
                visible={isShowModal}
                setVisible={setShowModal}
                itemSelected={itemSelected}
                setItemSelected={setItemSelected}
            />) : null}
            <Switch>
                <Route
                    exact
                    path="/okr/report_review_detail"
                    name="Drawer List"
                    render={() => <Detail url={`/okr/report_review_detail`} />}
                />
            </Switch>
        </div>
    );
};

export default PersonalReview;