import { Col, Row, Skeleton, Spin } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import List from './List';
import { useDispatch, useSelector } from "react-redux";
import ModelCreateCriteria from './ModelCreateCriteria';
import { getCriteria } from "../../../../api/okr";
import { useReload } from './reload';
const PersonalReview = () => {
    const { reloadKey } = useReload();
    const criteria = useSelector((state) => state.criteria);
    const [data, setData] = useState(null);
    const [isShowModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const getDataCriteria = useCallback(async () => {
        setLoading(true);
        setData(null)
        setTimeout(() => {
            getCriteria().then((response) => {
                setData(response.data.data);
                setLoading(false);
            });
        }, 500)
    }, []);

    useEffect(() => {
        getDataCriteria()
        console.log('Reloading data...', reloadKey);
    }, [getDataCriteria, reloadKey]);

    const onEdit = useCallback(async (record) => {
        setShowModal(true)
        setItemSelected(record)
    }, [])

    return (
        <div>
            <Row>
                <Col xs={24} style={{ textAlign: 'center' }}>
                    {data !== null ? <List data={data} onEdit={onEdit}/>: <div><List data={criteria} onEdit={onEdit}/></div>}
                </Col>
            </Row>
            {itemSelected ? (<ModelCreateCriteria
                visible={isShowModal}
                setVisible={setShowModal}
                itemSelected={itemSelected}
                setItemSelected={setItemSelected}
            />) : null}
        </div>
    );
};

export default PersonalReview;