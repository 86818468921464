import moment from "moment";

export const convertDatetime = (value) => {
  return moment(value).format("DD-MM-YYYY");
};

export const convertDateHour = (value) => {
  return moment(value).format("DD-MM-YYYY HH:mm");
};

export const convertSetTime = (value) => {
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
};

export const convertSetTimeStartOf = (value) => {
  return moment(value).startOf("day").format("YYYY-MM-DD HH:mm:ss");
};

export const convertSetTimeEndOf = (value) => {
  return moment(value).endOf("day").format("YYYY-MM-DD HH:mm:ss");
};

export const getDaysBetweenDates = (startDate, endDate) => {
  var now = startDate.clone(),
    dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("MM/DD/YYYY"));
    now.add(1, "days");
  }
  return dates;
};

export const getDateBetweenDates = (day, startDate, endDate) => {
  var dates = [];
  // Get "next" monday
  let tmp = startDate.clone().day(day);

  while (tmp.isSameOrBefore(endDate)) {
    if (tmp.isSameOrAfter(startDate)) {
      dates.push(tmp.format("YYYY-MM-DD"));
    }
    tmp.add(7, "days");
  }
  return dates;
};

export const classSetDueDate = (duedate, isComplete) => {
  if (duedate !== "") {
    if (!isComplete) {
      return moment(duedate).format("YYYY-MM-DD") <
        moment().format("YYYY-MM-DD")
        ? "due-date"
        : moment(duedate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ? "due-same-date"
          : "";
    }
  }
  return;
};

export const newTask = {
  created_at: moment().format("YYYY-MM-DD"),
  date: moment().format("ddd"),
  description: "",
  due_date: "",
  id: "new_task",
  idOld: "new_task",
  is_complete: false,
  name: "",
  section: null,
  assignee: null,
  parent_id: 0,
  start_date: "",
  updated_at: moment().format("YYYY-MM-DD"),
  tags: [],
  comments: [],
  project: null,
  children: [],
};

export const colorProgress = (progress) => {
  switch (true) {
    case progress >= 0 && progress <= 20:
      return "#CC2334";
    case progress > 20 && progress <= 40:
      return "#EF4C40";
    case progress > 40 && progress <= 60:
      return "#EF8035";
    case progress > 60 && progress <= 80:
      return "#EFB026";
    case progress > 80 && progress <= 100:
      return "#6EBF44";
    case progress > 100:
      return "#4FBD00";
    default:
      return "#F50000";
  }
};

export const toValidateName = (text) => {
  return text.trim().replace("\r");
};
export const isValidateName = (text) => {
  // let value = text.trim().replace(/[a-zA-Z09/s]/g, "");
  return text.length > 0;
};

export const isValidateTime = (time) => {
  return time !== "" && time !== null;
};

export const urlify = (text) => {
  // var urlRegex =/(\b(<a>https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  // return text.replaceAll(urlRegex, function (url) {
  //   return '<a target="_blank" href="' + url + '">' + url + "</a>";
  // });
  return text;
};

export const removeObjectNull = (queryString) => {
  let newObject = {};
  for (const [key, value] of Object.entries(queryString)) {
    if (value !== null) newObject = { ...newObject, [key]: value };
  }
  return newObject;
};

export const integerToRoman = (num) => {
  const romanValues = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let roman = '';
  for (let key in romanValues) {
    while (num >= romanValues[key]) {
      roman += key;
      num -= romanValues[key];
    }
  }
  return roman;
}

export const formatNumber = (num) => {
  if (num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return num;
}
