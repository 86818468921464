import { Form, Input, Modal, Row, Col, Checkbox, Select, Spin, InputNumber, DatePicker, message } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { getObjective, createObjective, editObjective } from "../../../api/okr";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { setObjective } from '../../../stores/actions/ork/objective';
import moment from 'moment';
import { getDepartmentsList } from '../../../api/ProjectGroup';
import { setDepartments } from '../../../stores/actions';

const { Option } = Select


const ModelUpdateObjective = ({ visible, setVisible, itemSelected, setItemSelected }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState("");
    const [is_staff, setIsStaff] = useState(itemSelected?.employee === 1 ? true : false);
    const [is_manager, setIsManage] = useState(itemSelected?.manager === 1 ? true : false);

    const localSearchFunc = (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const _handleSearch = useCallback((input) => {
        setFetching(true)
        setTimeout(() => {
            setSearch(input || "");
        }, 666000)
        setFetching(false)
    }, []);

    const [dataObjective, setDataObjective] = useState([]);
    const getDataObjective = useCallback(async () => {
        getObjective().then((response) => {
            setDataObjective(response.data.data);
            dispatch(setObjective(response.data.data));
        });
    }, [dispatch]);

    const [dataDepartments, setDataDepartments] = useState([]);
    const getDataDepartments = useCallback(async () => {
        getDepartmentsList().then((response) => {
            setDataDepartments(response.data.data);
            dispatch(setDepartments(response.data.data));
        });
    }, []);

    useEffect(() => {
        getDataDepartments();
    }, [])


    const onRefreshList = () => {
        getDataObjective();
    }


    useEffect(() => {
        getDataObjective()
    }, [getDataObjective]);

    const [form] = Form.useForm();
    const handleOk = () => {
        form.submit();
    };
    const handleCancel = () => {
        form.resetFields()
        setVisible(false);
        // eslint-disable-next-line no-unused-expressions
        setItemSelected(null)
        onRefreshList()
    };

    const handleCreateObjective = async (values) => {
        // const params = {
        //     id: itemSelected?.id,
        //     code: values?.code,
        //     name: values?.name,
        //     description: values?.description,
        //     parent_id: values?.parent_id,
        //     start_date: values?.start_date ? moment(values?.start_date).format('YYYY-MM-DD') : null,
        //     end_date: values?.start_date ? moment(values?.start_date).format('YYYY-MM-DD') : null,
        //     department_id: values?.department_id
        // }
        // console.log(params);
        // itemSelected ? editObjective(params).then((response) => {
        //     setDataObjective(response.data.data);
        //     onRefreshList()
        //     setItemSelected(null)
        // }) :
        //     createObjective(params).then((response) => {
        //         setDataObjective(response.data.data);
        //         onRefreshList()
        //         // setItemSelected(null)
        //         message.success('Thêm Objective thành công!');
        //     })
        //     ;
    }

    const onFinish = (values) => {
        form.resetFields()
        setVisible(false);
        handleCreateObjective(values)
    };

    const onChangeStaff = (e) => {
        setIsStaff(e.target.checked)

    };

    const onChangeManager = (e) => {
        setIsManage(e.target.checked)
    };

    return (
        <Modal
            title="Tạo mới Objective"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText="Huỷ"
            okText="Tạo"
            style={{ top: 0 }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
                initialValues={{
                    code: itemSelected && itemSelected.code || '',
                    name: itemSelected && itemSelected.name || '',
                    description: itemSelected && itemSelected.description || '',
                    parent_id: itemSelected && itemSelected.parent_id || '',
                    start_date: itemSelected && moment(itemSelected.start_date) || '',
                    end_date: itemSelected && moment(itemSelected.start_date) || '',
                    department_id: itemSelected && itemSelected.department_id || ''
                }}
            >
                <Row>
                    <Col xs={24}>
                        <Form.Item
                            name="code"
                            label="Mã Objective"
                            rules={[
                                {
                                    required: true,
                                    message: "Mã Objective không được bỏ trống",
                                }
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="Ví dụ: TECH 2021" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name="name"
                            label="Tên Objective"
                            rules={[
                                {
                                    required: true,
                                    message: "Tên Objective không được bỏ trống",
                                }
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="Ví dụ: TECH 2021" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name="description"
                            label="Mô tả Objective"
                            hasFeedback
                        >
                            <Input placeholder="Ví dụ: TECH 2021" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name={'parent_id'}
                            label={'Chọn Objective cấp 1'}
                        >
                            <Select
                                allowClear={true}
                                disabled={itemSelected && itemSelected?.children?.length !== 0 ? true : false}
                                // placeholder={"Tiêu chí"}
                                showSearch
                                style={{ width: '100%' }}
                                filterOption={localSearchFunc}
                                notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
                                onSearch={_handleSearch}
                                onChange={(item) => {
                                }}
                                placeholder="Chọn Objective">
                                {itemSelected ?
                                    _.map(dataObjective?.filter(item => item?.id !== itemSelected?.id), (item) => {
                                        return (<Option key={item?.id} value={item?.id}>{item?.name}</Option>)
                                    })
                                    :
                                    _.map(dataObjective, (item) => {
                                        return (<Option key={item?.id} value={item?.id}>{item?.name}</Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            label={"Thời gian áp dụng từ"}
                            name={'start_date'}
                        >
                            <DatePicker placeholder='Nhập thời gian' style={{ width: 200 }} />
                        </Form.Item>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'end' }} xs={12}>
                        <Form.Item
                            label={"Thời gian áp dụng đến"}
                            name={"end_date"}
                        >
                            <DatePicker placeholder='Nhập thời gian' style={{ width: 200 }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name={'department_id'}
                            label={'Phòng ban chịu trách nhiệm'}
                        >
                            <Select placeholder="Chọn phòng ban">
                                {itemSelected ?
                                    _.map(dataDepartments?.filter(item => item?.id !== itemSelected?.id), (item) => {
                                        return (<Option key={item?.id} value={item?.id}>{item?.name}</Option>)
                                    })
                                    :
                                    _.map(dataDepartments, (item) => {
                                        return (<Option key={item?.id} value={item?.id}>{item?.name}</Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
};

export default ModelUpdateObjective;