import { Form, Input, Modal, Row, Col, Checkbox, Select, Spin, InputNumber, message } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { getCriteria, createCriteria, editCriteria } from "../../../../api/okr";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { setCriteria } from "../../../../stores/actions/ork/criteria"
import { useReload } from './reload';
const { Option } = Select;
const ModelCreateObjective = ({ visible, setVisible, itemSelected, setItemSelected }) => {
    const dispatch = useDispatch();
    const { reload } = useReload();
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState("");
    const [is_staff, setIsStaff] = useState(itemSelected?.employee === 1 ? true : false);
    const [is_manager, setIsManage] = useState(itemSelected?.manager === 1 ? true : false);

    const localSearchFunc = (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const _handleSearch = useCallback((input) => {
        setFetching(true)
        setTimeout(() => {
            setSearch(input || "");
        }, 666000)
        setFetching(false)
    }, []);

    const [dataCriteria, setDataCriteria] = useState([]);
    const getDataCriteria = useCallback(async () => {
        getCriteria().then((response) => {
            // reload()
            setDataCriteria(response.data.data);
            dispatch(setCriteria(response.data.data));
        });
    }, [dispatch]);


    const onRefreshList = () => {
        getDataCriteria();
    }


    useEffect(() => {
        getDataCriteria()
    }, [getDataCriteria,visible]);

    const [form] = Form.useForm();
    const handleOk = () => {
        form.submit();
    };
    const handleCancel = () => {
        form.resetFields()
        setVisible(false);
        // eslint-disable-next-line no-unused-expressions
        itemSelected ? setItemSelected(null) : null
        setIsStaff(itemSelected?.employee === 1 ? true : false)
        setIsManage(itemSelected?.manager === 1 ? true : false)
    };

    const handleCreateCriteria = async (values) => {
        if(!(values?.employee)&&!(values?.manager)){
            message.error("Vui lòng chọn 1 đối tượng được áp dụng tiêu chí")
        }
        else {
            const params = {
                id: itemSelected?.id,
                name: values?.name,
                target: values?.target,
                parent_id: values?.parent_id,
                employee: values?.employee ? 1 : 0,
                manager: values?.manager ? 1 : 0,
                employee_weight: values?.employee ? values?.employee_weight : null,
                manager_weight: values?.manager ? values?.manager_weight : null,
                is_active: 1
            }
            itemSelected ? editCriteria(params).then((response) => {
                setDataCriteria(response.data.data);
                handleCancel()
                reload()
                setItemSelected(null)
    
            }) :
                createCriteria(params).then((response) => {
                    setDataCriteria(response.data.data);
                    handleCancel()
                    reload()
                })
                ;
        }
    }

    const onFinish = (values) => {
        form.resetFields()
        handleCreateCriteria(values)
    };

    const onChangeStaff = (e) => {
        setIsStaff(e.target.checked)

    };

    const onChangeManager = (e) => {
        setIsManage(e.target.checked)
    };

    return (
        <Modal
            title={itemSelected ? "Cập nhật tiêu chí" : "Tạo mới tiêu chí"}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText="Huỷ"
            okText={itemSelected ? "Cập nhật" : "Tạo mới"}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
                initialValues={{
                    name: itemSelected && itemSelected.name || '',
                    target: itemSelected && itemSelected.target || '',
                    parent_id: itemSelected && itemSelected.parent_id || '',
                    employee: itemSelected && itemSelected.employee || false,
                    employee_weight: itemSelected && itemSelected.employee_weight || '',
                    manager: itemSelected && itemSelected.manager || false,
                    manager_weight: itemSelected && itemSelected.manager_weight || '',
                }}
            >
                <Form.Item
                    name="name"
                    label="Tên tiêu chí"
                    rules={[
                        {
                            required: true,
                            message: "Tên tiêu chí không được bỏ trống",
                        }
                    ]}
                    hasFeedback
                >
                    <Input placeholder="Ví dụ: TECH 2021" />
                </Form.Item>
                <Form.Item
                    name="target"
                    label="Mục tiêu cần đạt"
                >
                    <Input placeholder="Ví dụ: TECH 2021" />
                </Form.Item>
                <Form.Item
                    name="parent_id"
                    label="Tiêu chí cấp 1"
                >
                    <Select
                        allowClear={true}
                        disabled={itemSelected && itemSelected?.children?.length !== 0 ? true : false}
                        placeholder={"Tiêu chí"}
                        showSearch
                        style={{ width: '100%' }}
                        filterOption={localSearchFunc}
                        notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
                        onSearch={_handleSearch}
                        onChange={(item) => {
                        }}
                    >
                        {itemSelected ?
                            _.map(dataCriteria?.filter(item => item?.id !== itemSelected?.id), (item) => {
                                return (<Option key={item?.id} value={item?.id}>{item?.name}</Option>)
                            })
                            :
                            _.map(dataCriteria, (item) => {
                                return (<Option key={item?.id} value={item?.id}>{item?.name}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>

                <Row gutter={[0, 8]} style={{ marginTop: -20 }}>
                    <Col span={12} style={{ alignItems: 'center' }}>
                        <div>&nbsp;</div>
                        <Form.Item
                            name="employee"
                            valuePropName="checked"
                        >
                            <Checkbox onChange={onChangeStaff}>Áp dụng nhân viên</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="employee_weight"
                            label="Trọng số"
                        >
                            <InputNumber disabled={!is_staff} placeholder="Nhập trọng số (%)" min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 8]} style={{ marginTop: -20 }}>
                    <Col span={12} style={{ alignItems: 'center' }}>
                        <div>&nbsp;</div>
                        <Form.Item
                            name="manager"
                            valuePropName="checked"
                        >
                            <Checkbox onChange={onChangeManager}>Áp dụng quản lý</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="manager_weight"
                            label="Trọng số"
                        >
                            <InputNumber disabled={!is_manager} placeholder="Nhập trọng số (%)" min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModelCreateObjective;