const project = (task = null, action) => {
  switch (action.type) {
    case "UPDATED_TASK":
      return { params: action.params, action: action.action };
     case "UPDATED_MULTI_TASKS":
        return { params: action.params, action: action.action };
    case "DELETED_TASK":
      return { params: action.params, action: action.action };
    case "DELETED_MULTI_TASKS":
      return { params: action.params, action: action.action };
    case "CREATED_TASK":
      return { params: action.params, action: action.action };
    case "CREATED_MULTI_TASK":
      return { params: action.params, action: action.action };
    case "MOVED_TASK":
      return { params: action.params, action: action.action };
    default:
      return null;
  }
};
export default project;
