import { combineReducers } from "redux";
import collapse from "./collapse";
import menu from "./menu";
import loading from "./loading";
import auth from "./auth";
import folderSelected from "./folderSelected";
import breadcrumbFolderRecent from "./breadcrumbFolderRecent";
import breadcrumbFolderLastUpFile from "./breadcrumbFolderLastUpFile";
import drawer from "./drawer";
import documentFile from "./documentFile";
import menuWork from "./menuWork";
import project from "./project";
import myTask from "./myTask";
import currentTask from "./currentTask";
import actionProject from "./actionProject";
import overview from "./overview";
import task from "./task";
import projectGroup from "./projectGroup";
import project_v2 from "./project_v2";
import criteria from "./criteria";
import actionProject_v2 from "./actionProject_v2";
import task_v2 from "./task_v2";
import section_v2 from "./section_v2";
import procedure from "./Workflow/procedure";
import actionProcedure from "./Workflow/actionProcedure";
import step from "./Workflow/step";
import departments from "./department";
import objective from "./objective";
import keyResult from "./keyResult";

export default combineReducers({
  collapse,
  menu,
  loading,
  auth,
  folderSelected,
  breadcrumbFolderRecent,
  breadcrumbFolderLastUpFile,
  drawer,
  documentFile,
  menuWork,
  project,
  myTask,
  currentTask,
  actionProject,
  overview,
  task,
  projectGroup,
  project_v2,
  criteria,
  actionProject_v2,
  task_v2,
  section_v2,
  procedure,
  actionProcedure,
  step,
  departments,
  objective,
  keyResult
});
