import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, DatePicker, Select, Button, Divider, Spin, Input } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { useSelector } from 'react-redux'
import locale from "antd/es/date-picker/locale/vi_VN";

const { Option } = Select;

let time = null;

const Filter = ({ className, setParams, params,  carDefinitions, autoSetting, setItemSelected }) => {
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState("");
    const _handleSearch = useCallback((input) => {
        setFetching(true)
        setTimeout(() => {
            setSearch(input || "");
        }, 666000)
        setFetching(false)
    }, []);

    const localSearchFunc = (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const onQuery = (value, name) => {
        clearTimeout(time);
        time = setTimeout(() => {
            setParams((preState) => {
                let nextState = { ...preState };
                nextState[name] = value;
                return nextState;
            });
        }, 500);
    };

    return (
        <div className={className}>
            <Row gutter={[8, 0]}>
                <Col span={4}>
                    <div>Tháng</div>
                    <DatePicker
                        allowClear={false}
                        onChange={(date) => {
                            setParams((prevState) => {
                                let nextState = { ...prevState };
                                nextState.month = date;
                                return nextState;
                            });
                        }}
                        style={{ width: '100%' }}
                        value={params.month}
                        locale={locale}
                        picker="month"
                        format={'MM-YYYY'}
                    />
                </Col>
                <Col span={4}>
                    <div>Nhân viên</div>
                    <Input
                        allowClear
                        placeholder="Nhân viên"
                        onChange={(e) => {
                            let { value } = e.target;
                            onQuery(value, "staff_info");
                        }}
                    />
                </Col>
                {/* <Col span={4}>
                    <div>Giao chỉ tiêu</div>
                    <Select
                        allowClear={true}
                        mode="multiple"
                        value={params?.status}
                        placeholder={"Giao chỉ tiêu"}
                        style={{ width: "100%" }}
                        onChange={(data) => {
                            onQuery(data, "status");
                        }}
                    >
                        {_.map(carDefinitions?.command_statuses, (item, itemId) => (
                            <Select.Option key={itemId} value={item.value}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={4}>
                    <div>Cá nhân đánh giá</div>
                    <Select
                        allowClear={true}
                        mode="multiple"
                        value={params?.status}
                        placeholder={"Cá nhân đánh giá"}
                        style={{ width: "100%" }}
                        onChange={(data) => {
                            onQuery(data, "status");
                        }}
                    >
                        {_.map(carDefinitions?.command_statuses, (item, itemId) => (
                            <Select.Option key={itemId} value={item.value}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={4}>
                    <div>Quản lý đánh giá</div>
                    <Select
                        allowClear={true}
                        mode="multiple"
                        value={params?.status}
                        placeholder={"Quản lý đánh giá"}
                        style={{ width: "100%" }}
                        onChange={(data) => {
                            onQuery(data, "status");
                        }}
                    >
                        {_.map(carDefinitions?.command_statuses, (item, itemId) => (
                            <Select.Option key={itemId} value={item.value}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col> */}

            </Row>
        </div>
    );
};
Filter.propTypes = {
    className: PropTypes.any,
};
export default styled(Filter)`
  .label {
    padding: 0px 0px 8px;
  }
  button,
  span {
    display: inline-flex;
    align-items: center;
  }
`;
