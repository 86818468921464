import React, { useState, useEffect } from "react";
import { Input, Modal, Cascader } from "antd";
import { useSelector } from "react-redux";

const PopupFolder = ({
  title,
  btnText,
  placeholder,
  handleAddFolder,
  visible,
  setVisible,
  data,
  treeSelect,
  description,
}) => {
  const initState = {
    disabled: true,
    value: data?.name,
    folder_id: null,
    description: data?.description,
  };

  const breadcrumbFolderRecent = useSelector((state) => state.breadcrumbFolderRecent);
  const [state, setState] = useState(initState);
  const [folderRecent, setFolderRecent] = useState(breadcrumbFolderRecent)
  
  const handleOk = () => {
    handleAddFolder(state.value, state.folder_id, state.description ?? "");
    setState([])
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
    setState(initState);
  };
  const handleChange = (e) => {
    let input = e.target.value;
    if (input.length > 0) {
      setState({ ...state, disabled: false, value: input });
    } else {
      setState({ ...state, disabled: true, value: input });
    }
  };

  const onchangeFolder = (value) => {
    setState({ ...state, folder_id: value[value.length - 1] });
    setFolderRecent(value);
  }

  const onChangeDescription = (e) => {
    let input = e.target.value;
    if (input.length > 0) {
      setState({ ...state, disabled: false, description: input });
    } else {
      setState({ ...state, description: input });
    }
  };

  function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  const renderMenuItem = (menu) => {
    return (
      menu.map((item) => {
        return {
          value: item.id,
          label: item.name,
          children: renderMenuItem(item.children)
        }
      }
      )
    )
  }

  const menu = useSelector((state) =>
    renderMenuItem(state.menu)
  );
  
  useEffect(() => {
    setFolderRecent(breadcrumbFolderRecent)
    setState({ ...state, 
      value: data?.name, 
      description: data?.description, 
      folder_id: breadcrumbFolderRecent[breadcrumbFolderRecent.length - 1] })
  }, [data, breadcrumbFolderRecent])


  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Huỷ"
      okText={btnText}
      okButtonProps={{ disabled: state.disabled }}
    >
      {treeSelect && (
        <>
          <span>Thư mục </span><br />
          <span style={{ color: "red" }}>(*) Bỏ trống nếu muốn tạo thư mục cấp 1</span>
          <Cascader
              value={folderRecent}
              options={menu}
              placeholder="Chọn nơi muốn tạo thư mục"
              changeOnSelect
              expandTrigger="hover"
              showSearch={{ filter }}
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={onchangeFolder}
            />
        </>
      )
      }
      <span>Tên </span>
      <Input
        placeholder={placeholder}
        onChange={handleChange}
        value={state.value}
        style={{ width: "100%", marginBottom: "10px" }}
      />
      <span>Mô tả </span>
      <Input
        placeholder="Nhập mô tả"
        onChange={onChangeDescription}
        onSelect={onChangeDescription}
        value={state.description}
      />
    </Modal>
  );
};
export default PopupFolder;
