const initState = {
  id: 0,
  key: 0,
};
const folderSelected = (folderSelected = initState, action) => {
  switch (action.type) {
    case "SET_FOLDER_SELECTED":
      return action.folderSelected
        ? {
            ...folderSelected,
            id: action.folderSelected.id,
            key: action.folderSelected.key,
          }
        : initState;
    default:
      return folderSelected;
  }
};
export default folderSelected;
