import React from 'react';
import { Table, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import 'antd/dist/antd.css';
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import icon_edit from "../../../../../assets/images/icons/icon_edit.png";
import { editCriteria, getCriteria } from "../../../../../api/okr";
import { setCriteria } from "../../../../../stores/actions/ork/criteria"
import { useReload } from '../reload';
const convertToRoman = (num) => {
    if (num <= 0 || num > 3999) return "Invalid number"; // Số La Mã hợp lệ từ 1 đến 3999

    const romanNumerals = [
        { value: 1000, symbol: 'M' },
        { value: 900, symbol: 'CM' },
        { value: 500, symbol: 'D' },
        { value: 400, symbol: 'CD' },
        { value: 100, symbol: 'C' },
        { value: 90, symbol: 'XC' },
        { value: 50, symbol: 'L' },
        { value: 40, symbol: 'XL' },
        { value: 10, symbol: 'X' },
        { value: 9, symbol: 'IX' },
        { value: 5, symbol: 'V' },
        { value: 4, symbol: 'IV' },
        { value: 1, symbol: 'I' },
    ];

    let result = '';

    for (let i = 0; i < romanNumerals.length; i++) {
        const { value, symbol } = romanNumerals[i];
        while (num >= value) {
            result += symbol;
            num -= value;
        }
    }

    return result;
};

const MyTable = ({ className, data, onEdit }) => {
    const dispatch = useDispatch();
    const { reload } = useReload();
    const onActive = async (e, value, row) => {
        const params = {
            id: row?.id,
            is_active: value === 1 ? 0 : 1,
            name: row?.name,
            target: row?.target,
            parent_id: row?.parent_id,
            employee: row?.employee ? 1 : 0,
            manager: row?.manager ? 1 : 0,
            employee_weight: row?.employee ? row?.employee_weight : null,
            manager_weight: row?.manager ? row?.manager_weight : null,
        }
        editCriteria(params).then((response) => {
            getCriteria().then((response) => {
                dispatch(setCriteria(response.data.data));
                reload()
            });
        });
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record, index) => {
                return record.parent_id == null ? (
                    <div style={{ textAlign: 'center' }}>{convertToRoman(index + 1)}</div>
                ) : (
                    <div style={{ textAlign: 'center' }}>{index + 1}</div>
                );
            },
        },
        {
            title: 'Tên tiêu chí',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        },
        {
            title: 'Mục tiêu cần đạt được',
            dataIndex: 'target',
            key: 'target',
            width: '35%',
        },
        {
            title: 'Trọng số NV (%)',
            dataIndex: 'employee_weight',
            key: 'employee_weight',
            width: '12%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>{record.children?.length > 0 ? null : text}</div>
                )
            }
        },
        {
            title: 'Trọng số QL (%)',
            dataIndex: 'manager_weight',
            key: 'manager_weight',
            width: '12%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>{record.children?.length > 0 ? null : text}</div>
                )
            }
        },
        {
            title: 'Active',
            dataIndex: 'is_active',
            key: 'is_active',
            width: '12%',
            render: (value, row) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={(e) => onActive(e, value, row)}
                            checked={value}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Edit',
            key: 'edit',
            width: '10%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <img alt="Edit" src={icon_edit} style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => onEdit(record)} />
                    </div>
                )
            },

        },
    ];
    const expandIcon = () => {
        return null;
    };

    return (
        <div className={className}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                defaultExpandAllRows={true}
                size="small"
                rowKey={'id'}
                scroll={{ x: "100vh", y: "calc(100vh - 200px)" }}
                expandable={{
                    expandRowByClick: false,
                    expandIcon,
                    expandIconColumnIndex: -1
                }}
                bordered
            />
        </div>
    );
};

MyTable.propTypes = {
    className: PropTypes.any,
};
export default styled(MyTable)`
.custom-table-container {
}
.ant-table-thead > tr > th {
  background-color: #1F78B4;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.ant-table-tbody > tr.ant-table-row-level-0 > td {
  background-color: #E9ECEE;
  font-weight:500;
  font-size: 14px;
}

.ant-table-tbody > tr.ant-table-row-level-1 > td {
  background-color: #ffff;
  font-weight:450;
  font-size: 13px;
}
  .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
    padding: 6px 4px;
}
`;

