import React, { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import {
  Select,
  Tag,
  List,
  Avatar,
  Modal,
  Badge,
  Tooltip,
  Popconfirm,
  Button,
} from "antd";
import {
  addAdmin,
  getListAdmin,
  getSearchAdmin,
  removeAdmin,
} from "../../../api/admin";
import _ from "lodash";

const { Option, OptGroup } = Select;

function tagRender(props) {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        margin: "4px",
        padding: "3px",
        borderRadius: "20px",
        background: "#F3F2F1",
        fontWeight: 600,
      }}
    >
      <Avatar icon={<UserOutlined />} style={{ marginRight: "5px" }} />
      {label}
    </Tag>
  );
}

const AvatarItem = (item) => {
  return (
    <Badge dot style={{ background: "green" }}>
      <Avatar icon={<UserOutlined />} />
    </Badge>
  );
};

const initialState = {
  visiable: false,
  isSave: false,
  itemSelected: [],
  data: [],
  options: [],
  total: 0,
};

const ShareAdmin = () => {
  const [state, setState] = useState(initialState);

  const handleChange = (selected) => {
    if (selected.length > 0) {
      setState({ ...state, isSave: true, itemSelected: selected });
    } else {
      setState({ ...state, isSave: false, itemSelected: [] });
    }
  };
  const handleRemoveUser = async (item, index) => {
    await removeAdmin(item.id);
    state.data.splice(index, 1);
    setState({ ...state, data: state.data, total: state.total - 1 });
  };
  const onSubmitSave = async (selected) => {
    await addAdmin({ users_id: selected });
    setState(initialState);
  };
  const hanldSearch = async (value) => {
    let response = await getSearchAdmin({ name: value });
    setState({ ...state, options: response.data.data });
  };
  const handleModal = async () => {
    let response = await getListAdmin();
    // await hanldSearch();
    setState({
      ...state,
      data: response.data.data,
      visiable: true,
      total: response.data.total,
    });
  };
  return (
    <div>
      <UserOutlined style={{ marginRight: "10px" }} />
      <span onClick={() => handleModal()}>Phân quyền</span>
      <Modal
        title={<h3>Phân quyền Admin</h3>}
        visible={state.visiable}
        footer={null}
        width={800}
        onCancel={() => setState(initialState)}
      >
        <span>Phân quyền cho</span>
        <Select
          mode="multiple"
          tagRender={tagRender}
          optionLabelProp="name"
          style={{ width: "100%", padding: "8px 0px" }}
          placeholder="Tìm kiếm tên"
          onChange={handleChange}
          onSearch={_.debounce(hanldSearch, 500)}
          showArrow={false}
          filterOption={false}
          value={state.itemSelected}
        >
          <OptGroup label="Tìm kiếm gợi ý">
            {state.options.map((item, index) => (
              <Option key={index} value={item.id} name={item.name}>
                <div style={{ display: "flex", paddingTop: "10px" }}>
                  <AvatarItem {...item} />
                  <div style={{ paddingLeft: "20px" }}>
                    <div>{item.name}</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      {item.email}
                    </div>
                  </div>
                </div>
              </Option>
            ))}
          </OptGroup>
        </Select>
        {!state.isSave ? (
          <span style={{ paddingBottom: "10px" }}>
            Thành viên: {state.total}
          </span>
        ) : null}
        <div
          style={{
            marginTop: "5px",
            borderTop: "1px solid #f0f0f0",
            height: "350px",
            overflowX: "scroll",
          }}
        >
          {!state.isSave ? (
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={state.data}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Tooltip
                      placement="bottom"
                      title="Loại bỏ thành viên này ra khỏi nhóm"
                    >
                      <Popconfirm
                        title="Bạn chắc chắn xoá user này chứ?"
                        onConfirm={() => handleRemoveUser(item, index)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <span style={{ cursor: "pointer" }}>Xoá</span>
                      </Popconfirm>
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<AvatarItem {...item} />}
                    title={item.name}
                    description={item.email}
                  />
                </List.Item>
              )}
            />
          ) : (
            <div style={{ display: "flex", alignItems: "end", justifyContent: 'center', height: '300px' }}>
              <Button
                type="primary"
                style={{ marginRight: "10px" }}
                onClick={() => onSubmitSave(state.itemSelected)}
              >
                Thêm
              </Button>
              <Button onClick={() => setState(initialState)}>Huỷ</Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ShareAdmin;
