import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { useState } from 'react';
import _ from 'lodash'
import { useHistory } from "react-router-dom";
const SearchForm = () => {

    const [options, setOptions] = useState()
    const history = useHistory();

    const handleInputChange = async (input) => {
        
    }

    const renderTitle = (title) => (
        <span>
            {title}
        </span>
    );

    const renderProjectSearch = (project) => ({
        key: project.id,
        value: project.name,
        label: (
            <>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 15, height: 15, background: "#FFC20E", marginRight: 10, borderRadius: "5px" }}></div>
                    <span>{project.name}</span>
                </div>
            </>
        ),
    });

    const selectSearch = (item, e) => {
       
    }


    return (
        <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={390}
            listHeight={600}
            style={{
                width: 400,
            }}
            options={options}
            onSearch={_.debounce(handleInputChange, 500)}
            onSelect={(value, e) => selectSearch(value, e)}
        >
            <Input
                style={{
                    height: "32px",
                    background: "#FFFFFF",
                    border: "1px solid #EDEBE9",
                    boxSizing: "border-box",
                    borderRadius: "4px"
                }}
                prefix={<SearchOutlined className="site-form-item-icon" />}
                placeholder="Tìm kiếm quy trình"
            />
        </AutoComplete>
    );
}

export default SearchForm