import _ from "lodash";

const project = (project = null, action) => {
  switch (action.type) {
    case "SET_PROJECT":
      return action.project;

    case "REMOVE_TASK":
      return {
        ...project,
        tasks: project.tasks.filter(function (task) {
          return task.id !== action.task.id;
        }),
      };

    case "SOCKET_SET_COMPELETED_TASK":
      return {
        ...project,
        tasks: project.tasks.map((task) => {
          if (task.id === action.id) {
            return {
              ...task,
              is_complete: !task.is_complete,
            };
          }
          return task;
        }),
      };
    case "SET_SECTION":
      return {
        ...project,
        sections: project.sections.map((section) => {
          if (section.id === action.id) {
            return Object.assign({}, section, action.params);
          }
          return section;
        }),
      };
    case "SET_SECTIONS":
      return { ...project, sections: action.sections };
    case "REMOVE_SECTION":
      return {
        ...project,
        sections: project.sections.filter(
          (section) => section.id !== action.section.id
        ),
      };
    case "ADD_SECTION":
      return {
        ...project,
        sections: _.concat(
          project.sections.slice(0, 1),
          [action.section],
          project.sections.slice(1)
        ),
      };
    case "SET_TASK":
      return {
        ...project,
        tasks: project.tasks.map((task) =>
          task.id === action.task.id
            ? Object.assign({}, task, action.task)
            : task
        ),
      };
    case "SET_TASKS":
      return {
        ...project,
        tasks: action.tasks,
      };
    case "ADD_TASK":
      return {
        ...project,
        tasks: _.concat(
          project.tasks.slice(0, action.index + 1),
          [action.task],
          project.tasks.slice(action.index + 1)
        ),
      };
    case "ADD_TASKS":
      return {
        ...project,
        tasks: _.concat(project.tasks, action.tasks),
      };
    default:
      return project;
  }
};
export default project;
