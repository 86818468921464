import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../src/assets/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import rootReducer from "./stores/reducers";
import { applyMiddleware, createStore } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/lib/integration/react";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = [thunk];

const persistConfig = {
  blacklist: [
    "menuWork",
    "menu",
    "project",
    "drawer",
    "myTask",
    "actionProject",
    "currentTask",
    "overview",
    "task",
    "actionProject_v2",
    "project_v2",
    "criteria",
    "task_v2",
    "section_v2",
    'procedure',
    'step',
    'curentStep',
    'actionProcedure'
  ],
  key: "root",
  storage: storage,
};
const pReducer = persistReducer(persistConfig, rootReducer);

var store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

window.STORE = store;

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
