import axios from "../plugin/axios";
import qs from 'querystring'

export const getCriteria = () => {
  return axios.get("/api/okr/evaluation-criteria");
};

export const createCriteria = (params) => {
  return axios.post("/api/okr/evaluation-criteria", params);
};

export const editCriteria = (params) => {
  return axios.put(`/api/okr/evaluation-criteria/${params?.id}`, params);
};
export const getStaffReview = (params) => {
  return axios.get(`/api/okr/staff-review?month=${params?.month}&page=${params?.page}&size=${params?.size}&staff_info=${params?.staff_info}`);
};
export const getDetailStaffReview = (id) => {
  return axios.get(`/api/okr/staff-review/${id}`);
};
export const updateKeyResult = (params) => {
  return axios.post(`/api/okr/staff-review/${params?.id}/review-key-result`, params);
};
export const updateValuationCriteria = (params) => {
  return axios.post(`/api/okr/staff-review/${params?.id}/review-evaluation-criteria`, params);
};

export const updateStaffReview = (params) => {
  return axios.put(`/api/okr/staff-review/${params?.id}`, params);
};

export const updateReviewTarget = (params) => {
  return axios.post(`/api/okr/staff-review/${params?.ids}/review-target`, params);
};



//
export const getObjective = () => {
  return axios.get('/api/okr/objective/list');
};

export const createObjective = (params) => {
  return axios.post('/api/okr/objective/store', params);
};

export const showObjective = (idObjective) => {
  return axios.get(`/api/okr/objective/show/${idObjective}`);
};

export const editObjective = (params) => {
  return axios.put(`/api/okr/objective/update/${params?.id}`, params);
}

export const getKeyResult = (params) => {
  const searchParams = new URLSearchParams(params);
  let name = params?.name ? params?.name : '';
  let objective_id = params?.objective_id ? params?.objective_id : '';
  let department_id = params?.department_id ? params?.department_id : '';
  return axios.get(`/api/okr/kr/list${params && `?${qs.stringify(params)}`}`);
};

export const createKeyResult = (params) => {
  return axios.post('/api/okr/kr/store', params);
};

export const showKeyResult = (idKeyResult) => {
  return axios.get(`/api/okr/kr/show/${idKeyResult}`);
};

export const editKeyResult = (params) => {
  return axios.put(`/api/okr/kr/update/${params?.id}`, params);
}

// Menu
export const getMenu = () => {
  return axios.get('/api/okr/menu');
}