import React, { useEffect } from "react";
import { Layout } from "antd";
import Sidebar from "./sidebar";
import Header from "../../../components/layouts/header";
import routes from "../routes";
import { Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ReloadProvider } from '../../OKRManagement/PersonalReview/Criteria/reload';
const { Content } = Layout;

const LayoutOKRManagement = () => {

  const location = useLocation();

  return (
    <ReloadProvider>
      <Layout style={{ height: "100vh", overflow: "hidden" }}>
        <Sidebar />
        <Layout className="site-layout">
          <Header
            objective={location.pathname === '/okr/objective' && true}
            keyResult={location.pathname === '/okr/key-result' && true}
            criteria={location.pathname === '/okr/criteria_review' && true}
            detailReport={location.pathname === '/okr/report_review_detail' && true}
          />
          <Content
            className="site-layout-background"
            style={{
              padding: 12,
            }}
          >
            <Switch>
              {routes?.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => {
                        document.title = route.name;
                        return <route.component {...props} />
                      }}
                    />
                  )
                );
              })}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </ReloadProvider>
  );
};

export default LayoutOKRManagement;
