import axios from '../plugin/axios';

export const getListAdmin = async (params) => {
    return await axios.get("/api/admin", params).then((response) => {
        return response;
    }).catch(err => {

    })
}

export const getSearchAdmin = async (params) => {
    return await axios.get("/api/search-admin", {params}).then((response) => {
        return response;
    }).catch(err => {

    })
}

export const addAdmin = async (params) => {
    return await axios.post("/api/admin", params).then((response) => {
        return response;
    }).catch(err => {

    })
}


export const removeAdmin = async (params) => {
    return await axios.get(`/api/remove-admin/${params}`).then((response) => {
        return response;
    }).catch(err => {

    })
}
