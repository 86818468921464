const menu = (menu = [], action) => {
  switch (action.type) {
    case "SET_MENU":
      return action.menu;
    case "SET_WORK_MENU":
      return action.menu;
    default:
      return menu;
  }
};
export default menu;
