import React from "react";

import haivan from "../../assets/images/logo.HV.svg";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Logo = (props) => {
  const changeCollapse = () => {
    props.toggleCollapse();
  };

  return (
    <div style={{display: 'flex', fontSize: '23px'}}>
      <div className="logo">
        <Link to="/">{props.collapse ? "" : <img src={haivan} alt="Haivan" style={{ width: "80%" }}></img>}</Link>
      </div>
      <div className="collapse" style={{display:'flex', alignItems: 'center', padding:'0 5px'}}>
        <span onClick={changeCollapse} style={{cursor: 'pointer'}}>
        {props.collapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined/>}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCollapse: () =>
      dispatch({
        type: "TOGGLE_COLLAPSE",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
