const project = (project = [], action) => {
    switch (action.type) {
        case "SET_DATA_MY_TASK":
            return action.project;
        case "SET_MARK_COMPLETE_MY_TASK":
            return project.map((task) => {
                if (task.id === action.task.id) {
                    if (!action.task.is_complete) {
                        return { ...task, is_complete: !action.task.is_complete, progress: 100 };
                    }
                    return { ...task, is_complete: !action.task.is_complete };
                }
                return task;
            });

        case "SET_RENAME_MY_TASK":

            return project.map((task) => {
                if (task.id === action.task.id) {
                    return { ...task, name: action.task.name };
                }
                return task;
            });

        case "SET_TIME_MY_TASK":
            return project.map((task) => {
                if (task.id === action.task.id) {
                    return {
                        ...task,
                        start_date: action.task.start_date,
                        due_date: action.task.due_date,
                    };
                }
                return task;
            });

        case "SET_ASSIGNEE_MY_TASK":
            return project.map((task) => {
                if (task.id === action.task.id) {
                    return {
                        ...task,
                        assignee: action.task.assignee,
                    };
                }
                return task;
            });

        case "CREATE_MY_TASK":
            project.unshift(action.task);
            return project;

        case "SET_PROGRESS_MY_TASK":
            return project.map((task) => {
                if (task.id === action.task.id) {
                    return {
                        ...task,
                        progress: action.task.progress,
                    };
                }
                return task;
            });

        case "REMOVE_MY_TASK":
            return project.filter(function (task) {
                return task.id !== action.task.id;
            });

        case "SET_DESCRIPTION_MY_TASK":
            return project.map((task) => {
                if (task.id === action.task.id) {
                    return {
                        ...task,
                        description: action.task.description,
                    };
                }
                return task;
            });

        default:
            return project;
    }
};
export default project;
