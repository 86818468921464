export const setProject = (project) => ({
  type: "SET_PROJECT",
  project,
});

export const socketSetCompletedTask = (id) => ({
  type: "SOCKET_SET_COMPELETED_TASK",
  id,
});

export const setSection = (section) => ({
  type: "SET_SECTION",
  section,
});

export const setSections = (sections) => ({
  type: "SET_SECTIONS",
  sections,
});

export const addSection = (section) => ({
  type: "ADD_SECTION",
  section,
});

export const removeSection = (section) => ({
  type: "REMOVE_SECTION",
  section,
});

export const setTask = (task) => ({
  type: "SET_TASK",
  task,
});

export const addTask = (task, index = 0) => ({
  type: "ADD_TASK",
  task,
  index
});

export const addTasks = (tasks) => ({
  type: "ADD_TASKS",
  tasks,
});

export const removeTask = (task) => ({
  type: "REMOVE_TASK",
  task,
});

export const setTasks = (tasks) => ({
  type: "SET_TASKS",
  tasks,
});
