import React, { useState } from 'react';
import { Input, AutoComplete } from 'antd';
import { Link } from "react-router-dom";
import _ from 'lodash';
import { getSearch } from "../../api/foldes";
import { SearchOutlined } from '@ant-design/icons';
import '../../assets/css/search.css';
import iconPp from "../../assets/images/icons/PP.svg";
import iconWord from "../../assets/images/icons/word.svg";
import iconEx from "../../assets/images/icons/ex.svg";
import folder from "../../assets/images/icons/folder_no_file.svg";
import pdf from "../../assets/images/icons/pdf.svg";
import fileIcon from "../../assets/images/icons/ant-design_file-add-outlined.svg";
import { convertDatetime } from "../../helper/helper";

const SearchFrom = () => {
    const [options, setOption] = useState([]);

    const handleInputChange = async (value) => {
        if (value.length > 3) {
            getSearch(value).then((response) => {
                const newOptions = [
                    {
                        label: renderTitle('Tệp'),
                        options: response.data.file.map((item, key) => {
                            switch (item?.type) {
                                case "docx":
                                    return renderItemFile(item, iconWord, key);
                                case "doc":
                                    return renderItemFile(item, iconWord, key);
                                case "pptx" || "ppt":
                                    return renderItemFile(item, iconPp, key);
                                case "ppt":
                                    return renderItemFile(item, iconPp, key);
                                case "xls":
                                    return renderItemFile(item, iconEx, key);
                                case "xlsx":
                                    return renderItemFile(item, iconEx, key);
                                case "pdf":
                                    return renderItemFile(item, pdf, key);
                                default:
                                    return renderItemFile(item, fileIcon, key);
                            }
                        })
                    },
                    {
                        label: renderTitle('Thư mục'),
                        options: response.data.folder.map((item, key) => {
                            return renderItemFolder(item, key);
                        })
                    }
                ];

                setOption(newOptions)
            }).catch(() => {
                setOption([])
            });
        }
    };

    const renderTitle = (title) => (
        <span className="title-search">
            {title}
        </span>
    );

    const renderItemFile = (item, icon, key) => ({
        key: `file`+ key,
        value: item.name,
        label: (
            <Link to={`/qltl/${item?.id}/xem-tai-lieu-${item?.slug}`} title={item?.name}>
                <div className="search-item">
                    <div className="icon-item-search">
                        <img src={icon} width="21" />
                    </div>
                    <div className="name-item-search">
                        <div>{item?.name.substring(0, 65)}{ item?.name.length > 65 && "..."}</div>
                        <span className="name-user-create">{item?.create_by?.name}</span>
                    </div>
                    <div className="date-item-search">{convertDatetime(item.created_at)}</div>
                </div>
            </Link>
        ),
    });

    const renderItemFolder = (item, index) => ({
        key: 'folder' + index,
        value: item.name,
        label: (
            <Link to={`/qltl/${item?.id}/tai-lieu-${item?.slug}`} >
                <div className="search-item">
                    <div className="icon-item-search">
                        <img src={folder} width="21" />
                    </div>
                    <div className="name-item-search">
                        <span>{item?.name.substring(0, 40)}</span><br />
                    </div>
                </div>
            </Link>
        ),
    });

    return (
        <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={720}
            listHeight={500}
            style={{
                width: 400,
            }}
            options={options}
            onSearch={_.debounce(handleInputChange, 500)}
        >
            <Input
                style={{
                    height: "32px",
                    // width: "390px",
                    background: "#FFFFFF",
                    border: "1px solid #EDEBE9",
                    boxSizing: "border-box",
                    borderRadius: "4px"
                }}
                prefix={<SearchOutlined className="site-form-item-icon" />}
                placeholder="Tìm kiếm tài liệu"
            />
        </AutoComplete>
    );
}

export default SearchFrom;