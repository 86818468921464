const project = (task = null, action) => {
    switch (action.type) {
      case "UPDATED_SECTION":
        return { params: action.params, action: action.action };
      case "DELETED_SECTION":
        return { params: action.params, action: action.action };
      case "CREATED_SECTION":
        return { params: action.params, action: action.action };
      default:
        return null;
    }
  };
  export default project;
  