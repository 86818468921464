import { ReconciliationOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Input, Row, Select, Spin, TreeSelect } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

let inputTimer = null;
const { Option } = Select;
const { TreeNode } = TreeSelect;

const Filter = ({ showModal, params, setParams, btnLoading, objective, departments }) => {

    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState("");

    const _changeQuery = useCallback(
        (payload) => {
            if (inputTimer) {
                clearTimeout(inputTimer);
            }
            inputTimer = setTimeout(() => {
                setParams((prevState) => {
                    let nextState = { ...prevState };
                    nextState[payload.name] = payload.value;
                    // nextState.page = 1;
                    return nextState;
                });
            }, 900);
        },
        [setParams]
    );

    const onChangeTenKR = (value) => {
        setParams({ ...params, name: value?.target?.value });
    }

    const onChangeObjective = (value) => {
        setParams({ ...params, objective_id: value });
    }

    const onChangeDepartment = (value) => {
        setParams({ ...params, department_id: value });
    }

    const localSearchFunc = (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const _handleSearch = useCallback((input) => {
        setFetching(true)
        setTimeout(() => {
            setSearch(input || "");
        }, 666000)
        setFetching(false)
    }, []);

    const renderTreeNodes = (data) =>
        data?.map((item) => {
            if (item.children && item.children.length) {
                return (
                    <TreeNode title={item.name} key={item.id} value={item.id} >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.name} key={item.id} value={item.id} />;
        });

    const FilterDepartment = () => (
        <TreeSelect
            showSearch
            style={{ width: "100%" }}
            value={params?.objective_id}
            placeholder="Chọn Objective"
            allowClear
            treeDefaultExpandAll
            onChange={(value) => {
                setParams((props) => {
                    let nextState = { ...props };
                    nextState["objective_id"] = value;
                    return nextState;
                });
            }}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
                minWidth: 300
            }}
            filterTreeNode={(inputValue, treeNode) =>
                treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
        >
            {renderTreeNodes(objective)}
        </TreeSelect>
    );

    return (
        <>
            <Row>
                <Col xs={12}>
                    <Row style={{ display: 'flex' }}>
                        <Col style={{ paddingRight: 5 }} span={8}>
                            <label>Tên KR</label>
                            <Input
                                value={params?.name}
                                onChange={(e) => {
                                    // _changeQuery({ name: 'name', value: e?.target?.value || params?.name });
                                    // onChangeTenKR(e);
                                    setParams((props) => {
                                        let nextState = { ...props };
                                        nextState["name"] = e?.target?.value;
                                        return nextState;
                                    });
                                }}
                                placeholder='Nhập tên KR' />
                        </Col>
                        <Col style={{ paddingRight: 5 }} span={8}>
                            <label>Objective</label>
                            {/* <Select
                                allowClear
                                onChange={(e) => {
                                    // _changeQuery({ name: 'objective_id', value: e });
                                    onChangeObjective(e)
                                }}
                                style={{ width: '100%' }}
                                placeholder='Chọn Objective'
                                showSearch
                                filterOption={localSearchFunc}
                                notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
                                onSearch={_handleSearch}
                            >
                                {objective?.map(item => {
                                    return <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                })}
                            </Select> */}
                            <FilterDepartment />

                        </Col>
                        <Col style={{ paddingRight: 5 }} span={8}>
                            <label>Phòng ban</label>
                            <Select
                                allowClear
                                value={params?.department_id}
                                onChange={(e) => {
                                    // _changeQuery({ name: 'department_id', value: e });
                                    // onChangeDepartment(e);
                                    setParams((props) => {
                                        let nextState = { ...props };
                                        nextState["department_id"] = e;
                                        return nextState;
                                    });
                                }}
                                style={{ width: '100%' }}
                                placeholder='Chọn phòng ban'
                                showSearch
                                filterOption={localSearchFunc}
                                notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
                                onSearch={_handleSearch}
                            >
                                {departments?.map(item => {
                                    return <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                    // return <Select.OptGroup key={item?.id} label={item?.name}>
                                    //     {item?.children?.map(item2 => {
                                    //         return (
                                    //             <Option key={item2?.id} value={item2?.id}>{item2?.name}</Option>
                                    //         );
                                    //     })}
                                    // </Select.OptGroup>
                                })}
                            </Select>

                        </Col>
                    </Row>
                </Col >
            </Row >
        </>
    );
};

export default Filter;