import { Input, InputNumber, message } from "antd";
import styled from "styled-components";
import React, { memo, useCallback, useState, useRef } from "react";
import { useEffect } from "react";
import { updateKeyResult, updateValuationCriteria } from "../../../../../api/okr";
import _ from 'lodash'
const { TextArea } = Input;
let inputTimer = null;
const Priority = memo(({ className, defaultValue, isResult, record, id, recordId, field, onRefreshList }) => {
    const [value, setValue] = useState(defaultValue);
    const previousValueRef = useRef('');
    const handleInputChange = (e) => {
        setValue(e);
    };

    const handlePressEnter = useCallback(async () => {
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            const params = {
                id: id,
                key_result_id: record?.key_result_id,
                evaluation_criteria_id: record?.id,
                priority: field == 'priority' ? value : undefined,
                result: field == 'result' ? value + "" : undefined,
                review_employee: field == 'review_employee' ? value : undefined,
                review_manager: field == 'review_manager' ? value : undefined,
                point: field == 'point' ? value : undefined,
                note: field == 'note' ? value + "" : undefined,
                task_id:record?.id

            }
            if (isResult) {
                updateKeyResult(params)
                    .then(() => {
                        setValue(value)
                    })
                    .catch(() => {
                        setValue(defaultValue)
                    })
            }
            else {
                updateValuationCriteria(params)
                    .then(() => {
                        setValue(value)
                    })
                    .catch(() => {
                        setValue(defaultValue)
                    })
            }
            onRefreshList()
        }, 300);
    }, [value])

    const handlePaste = useCallback(async (e) => {
        const pastedValue = (e.clipboardData || window.clipboardData).getData('text').replace(/,/g, '');
        setValue("")
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            const params = {
                id: id,
                key_result_id: record?.key_result_id,
                evaluation_criteria_id: record?.id,
                priority: field == 'priority' ? pastedValue : undefined,
                result: field == 'result' ? pastedValue + "" : undefined,
                review_employee: field == 'review_employee' ? pastedValue : undefined,
                review_manager: field == 'review_manager' ? pastedValue : undefined,
                point: field == 'point' ? pastedValue : undefined,
                note: field == 'note' ? pastedValue + "" : undefined,
                task_id:record?.id

            }
            if (isResult) {
                updateKeyResult(params)
                    .then(() => {
                        setValue(pastedValue)
                    })
                    .catch(() => {
                        setValue(defaultValue)
                    })
            }
            else {
                updateValuationCriteria(params)
                    .then(() => {
                        setValue(value)
                    })
                    .catch(() => {
                        setValue(defaultValue)
                    })
            }
            onRefreshList()
        }, 300);
    }, [recordId])


    const handleBlur = useCallback(async () => {
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            if (value !== defaultValue) {
                const params = {
                    id: id,
                    key_result_id: record?.key_result_id,
                    evaluation_criteria_id: record?.id,
                    priority: field == 'priority' ? value : undefined,
                    result: field == 'result' ? value + "" : undefined,
                    review_employee: field == 'review_employee' ? value : undefined,
                    review_manager: field == 'review_manager' ? value : undefined,
                    point: field == 'point' ? value : undefined,
                    note: field == 'note' ? value + "" : undefined,
                    task_id:record?.id
                }
                if (isResult) {
                    updateKeyResult(params)
                        .then(() => {
                            setValue(value)
                        })
                        .catch(() => {
                            setValue(defaultValue)
                        })
                }
                else {
                    updateValuationCriteria(params)
                        .then(() => {
                            setValue(value)
                        })
                        .catch(() => {
                            setValue(defaultValue)
                        })
                }
            }
            onRefreshList()
        }, 300);
    }, [value])

    React.useEffect(() => {
        previousValueRef.current = defaultValue;
    }, [defaultValue]);

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue]);


    return (
        <div className={className} style={{ textAlign: 'center' }}>
            {field === "note" || field === "result" ?
                <TextArea 
                    // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={value}
                    bordered={false}
                    style={{ width: '100%', color: '#000' }}
                    onChange={(e) => handleInputChange(e?.target?.value)}
                    onPressEnter={handlePressEnter}
                    onPaste={handlePaste}
                    onBlur={handleBlur}
                    rows={3}
                />
                : <InputNumber min={0}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={value}
                    bordered={false}
                    style={{ width: '100%', color: '#000', textAlign: 'center' }}
                    onChange={(e) => handleInputChange(e)}
                    onPressEnter={handlePressEnter}
                    onPaste={handlePaste}
                    onBlur={handleBlur}
                />}
        </div>
    );
});
export default styled(Priority)`
.ant-input-number-handler-wrap { 
  display:none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 4px;
  text-align: center;
  background-color: transparent;
  border: 0; 
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
`
