/**
 * Input (Styled Component)
 */
import React, { useCallback } from "react";
import styled from "styled-components";
import { Table } from "antd";
import PropTypes from "prop-types";
// import * as style from "components/Variables";
import { random } from "lodash";

const StyledInput = ({
  className,
  columns,
  dataSource,
  summary,
  scroll_Y,
  rowSelection,
  sticky,
  scroll,
  rowKey = "key",
  rowClassName,
  expandable,
  showHeader,
  components,
  onRow,
}) => {
  let maxHeight = window.innerHeight;
  maxHeight = (maxHeight * 62) / 100;
  let objScroll = { x: "100%" };
  if (maxHeight) {
    objScroll = { ...objScroll, ...{ y: scroll_Y ? scroll_Y : maxHeight } };
  }
  return (
    <div className={className}>
      <Table
        bordered
        columns={columns}
        dataSource={dataSource}
        rowClassName={rowClassName}
        expandable={expandable}
        showHeader={showHeader}
        rowSelection={rowSelection}
        // scroll={scroll == false ? false : objScroll}
        scroll={scroll == false ? false : scroll}
        sticky={true}
        // rowKey={() => {
        //   return new Date().getTime()
        // }}
        rowKey={rowKey}
        summary={summary}
        pagination={false}
        components={components}
        onRow={onRow}
      />
    </div>
  );
};

StyledInput.propTypes = {
  className: PropTypes.any,
  placeholder: PropTypes.any,
};
export default styled(StyledInput)`
  .ant-row-end {
    // display: none
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
  }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .ant-table-thead > tr > th {
    background: #0078D4;
    color: #fff;
    font-family: Segoe UI;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .ant-table-expanded-row-fixed {
    height: 100px;
    .ant-empty-normal {
      margin: 2px 0;
      .ant-empty-normal .ant-empty-image {
        height: 30px;
      }
    }
  }
  .ant-table-summary {
    font-weight: bold;
    text-align: right;
    .ant-table-cell {
      background-color: rgb(242, 243, 248);
      position: sticky;
      z-index: 10000;
      bottom: 0;
    }
  }
`;
