import { markComplete } from "../../api/task";

const initalState = {
    taskId: 0,
    projectId: 0,
    action: '',
    data: []
};

const task = (data = initalState, action) => {
    switch (action.type) {
        case 'COMPLETE_TASK':
            markComplete(action.data.projectId, action.data.taskId, { status: action.data.data.is_complete })
            return action.data;
        default:
            return data
    }
}

export default task;
