require('./project');
require('./myTask');
require('./Workflow/procedure')

export const toggleCollapse = (collapse) => ({
  type: "TOGGLE_COLLAPSE",
  collapse,
});

export const setMenuRedux = (menu) => ({
  type: "SET_MENU",
  menu,
});

export const setLoading = (loading) => ({
  type: "SET_LOADING",
  loading,
});

export const actionLogin = (auth) => ({
  type: "LOGIN",
  auth,
});

export const actionLogout = (auth) => ({
  type: "LOGOUT",
  auth,
});

export const setFolderSelected = (folderSelected) => ({
  type: "SET_FOLDER_SELECTED",
  folderSelected,
});

export const setBreadcrumbFolderRecent = (breadcrumb) => ({
  type: "SET_BREADCRUMB_FOLDER_RECENT",
  breadcrumb,
});

export const setBreadcrumbFolderLastUpFile = (breadcrumb) => ({
  type: "SET_BREADCRUMB_FOLDER_LAST_UP_FILE",
  breadcrumb,
});

// work manager

export const toggleDrawer = (drawer) => ({
  type: "TOGGLE_DRAWER",
  drawer,
});

export const toggleFileFormat = (file) => ({
  type: "TOGGLE_FILE_FORMAT",
  file,
});

export const toggleDocumentFormat = (document) => ({
  type: "TOGGLE_DOCUMENT_FORMAT",
  document,
});

export const setMenuWork = (menu) => ({
  type: "SET_MENU_WORK",
  menu,
});

export const setLocationTask = (location) => ({
  type: "SET_LOCATION_TASK",
  location
})

export const setDepartments = (departments) => ({
  type: "SET_DEPARTMENTS",
  departments
})

