import React from 'react';

const Home = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      Quản lý OKR
    </div>
  );
};

export default Home;