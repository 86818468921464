import axios from "../../plugin/axios";

export const listDepartment = (params) => {
    return axios.get(`api/timekeeping/department`, {params});
};

export const editManagerOfSDepartment = (departmentId, managerId) => {
    
    return axios.post(`api/timekeeping/department/${departmentId}/edit-manager`, {manager_id: managerId});
};

