import axios from "../../plugin/axios";

export const importDataTimesheet = (params) => {
    return axios.post(`api/timekeeping/timesheet/import`, params);
};

export const timesheetSummary = (departmentId, month, year, search) => {
    return axios.get(`api/timekeeping/timesheet-summary?department_id=${departmentId}&month=${month}&year=${year}&search=${search}`);
};

export const timesheetSummaryDetail = (staffId, date) => {
    return axios.get(`api/timekeeping/timesheet-summary/${staffId}?date=${date}`);
};

export const approveTimesheetDetail = (staffId, startDate, endDate, status, reasonRefusal) => {
    return axios.post(`api/timekeeping/timesheet-summary/${staffId}/approve-timesheet-detail`, {
        start_date: startDate,
        end_date: endDate,
        status: status,
        reason_refusal: reasonRefusal
    });
};