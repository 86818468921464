const inital = {
  favorites: [],
  joins: [],
  my_project: [],
};
const menu = (menu = inital, action) => {
  switch (action.type) {
    case "SET_MENU_WORK":
      return action.menu;
    default:
      return menu;
  }
};
export default menu;
