import { Input, message, Modal, Form, Select, Checkbox, TreeSelect } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { createProject } from "../../../../api/project";
import { setMenuWork } from "../../../../stores/actions";
import React, { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../../../plugin/axios";
const { TreeNode } = TreeSelect;
const ModalAddProject = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const menuWork = useSelector(state => state.menuWork)
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const [departmentList, setDepartmentList] = useState([]);
  const [value, setValue] = useState(undefined);
  const [dis, setDis] = useState(true);
  const handleOk = async () => {
    form.submit();
  };

  const onFinish = (value) => {
    const params = {
        name: value?.name,
        description: value?.description,
        department_id: value?.department_id,
        auto_include_member: value?.department_id !==undefined ? (value?.auto_include_member ? 1: 0) :0,
        is_kpi: value?.is_kpi ? 1: 0
    }
    createProject(params)
      .then((response) => {
        message.success("Tạo dự án thành công");
        form.resetFields();
        props.setVisible(false);
        let project = {
          id: response.data.data.id,
          name: response.data.data.name,
          is_kpi:  response.data?.data?.is_kpi
        };
        menuWork.my_project.unshift(project);
        menuWork.joins.unshift(project);
        dispatch(setMenuWork({ ...menuWork, my_project: menuWork.my_project, joins: menuWork.joins }));
        return response;
      })
      .then((response) => history.push(`/qlcv/${response.data.data.id}/list`))
      .catch((err) => {
        message.error("Tạo dự án không thành công");
      });
  };

  const handleCancel = () => {
    form.resetFields();
    props.setVisible(false);
  };

  const getDataDepartment = async () => {
    return await axiosInstance
      .get(`/api/tasking/get-departments?user_id=${auth?.user?.id}`)
      .then((res) => {
        setDepartmentList(res.data.data);
      })
      .catch((err) => {
        message.error("Có lỗi xảy ra!");
      });
  };


  useEffect(() => {
    getDataDepartment()
  }, [])


  useEffect(() => {
    value !== undefined ? setDis(false) : setDis(true)
  }, [value])

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children && item.children.length) {
        return (
          <TreeNode title={item.name} key={item.id} value={item.id}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={item.name} key={item.id} value={item.id} />;
    });

  const onChange = (newValue) => {
    setValue(newValue);
  };



  return (
    <Modal
      title="Tạo mới dự án"
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Huỷ"
      okText="Tạo mới"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFinish={onFinish}
        initialValues={{
          dmo_name: '',
          dmo_intro: '',
        }}
      >
        <Form.Item
          name="name"
          label="Tên dự án"
          rules={[
            {
              required: true,
              message: "Tên dự án không được để trống",
            }
          ]}
          hasFeedback
        >
          <Input placeholder="Ví dụ: TECH 2024" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Mô tả dự án"
          rules={[
            {
              required: true,
              message: "Mô tả dự án không được để trống",
            },
            {
              min: 10,
              message: "Mô tả dự án phải lớn hơn 10 ký tự",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="Ví dụ: TECH 2024" />
        </Form.Item>
        <Form.Item
          name="department_id"
          label="Phòng ban"
        >
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Chọn phòng ban"
            allowClear
            treeDefaultExpandAll
            onChange={onChange}
            filterTreeNode={(inputValue, treeNode) =>
              treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
          >
            {renderTreeNodes(departmentList)}
          </TreeSelect>
        </Form.Item>
        <Form.Item
          name="auto_include_member"
          style={{ marginTop: -20 }}
          valuePropName="checked"
        >
          <Checkbox disabled={dis}>Thêm tất cả nhân viên cùng phòng</Checkbox>
        </Form.Item>
        <Form.Item
          name="is_kpi"
          style={{ marginTop: -20 }}
          valuePropName="checked"
        >
          <Checkbox>Dự án KPI</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddProject;
