import React, { useEffect, useState } from 'react';
import { Input, Modal, Form, Select } from 'antd';
import { createProcedures } from '../../../api/Workflow/procedure';

const ModalCreateProcedure = ({ visible, setVisible }) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        form.resetFields()
        setVisible(false);
    };

    const handleCreateProcedure = async (name, description) => {
        createProcedures(name, description).then((response) => {
            
        })
    }

    const onFinish = (value) => {
        form.resetFields()
        setVisible(false);
        handleCreateProcedure(value.name, value.description ?? "")
    };

    return (
        <Modal
            title="Tạo quy trình"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText="Huỷ"
            okText="Lưu"
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    label="Tên quy trình"
                    rules={[
                        {
                            required: true,
                            message: "Tên quy trình không được bỏ trống",
                        }
                    ]}
                    hasFeedback
                >
                    <Input placeholder="Ví dụ: Quy trình mua thiết bị" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Mô tả"
                    hasFeedback
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalCreateProcedure;