import _ from "lodash";

const projectGroup = (projectGroup = null, action) => {
  switch (action.type) {
    case "SET_PROJECT_GROUP":
      return action.projectGroup;
    default:
      return projectGroup;
  }
};
export default projectGroup;
