import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Col, Row, Table, Typography, Radio, Input, Checkbox } from 'antd';
import haivan from "../../../../../assets/images/logo.HV.png";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
// import './tableCss.css';
import { updateStaffReview, updateValuationCriteria } from "../../../../../api/okr";
import { mySharedRef } from './myRefs';
import TextArea from 'antd/lib/input/TextArea';
import InputField from './InputField';
import InputTarget from './InputTarget';
let inputTimer;
const Detail = ({ className, data, setData, onRefreshList, id }) => {
    function calculateTotalPriorityKey(data) {
        let totalPriority = 0;

        data?.key_results?.forEach(keyResult => {
            totalPriority += keyResult.priority || 0;
        });

        return totalPriority;
    }

    function calculateTotalPriorityAllxKey(value) {
        return value?.length > 0 && value.reduce((total, item) => {
            const itemTotalPriority = item.children.reduce((sum, child) => sum + (child.point * child.priority), 0);
            return total + itemTotalPriority;
        }, 0);
    }


    // function calculateTotalPriorityAllxKey(data) {
    //     let totalEmployee = 0;

    //     function calculateCriteria(criteria) {
    //         // totalEmployee += (criteria.priority * criteria.point) || 0;

    //         if (criteria?.children && criteria?.children?.length > 0) {
    //             criteria?.children?.forEach(child => {
    //                 calculateCriteria(child); // Gọi đệ quy để xử lý các child
    //             });
    //         }
    //     }
    //     data?.evaluation_criteria?.forEach(criteria => {
    //         calculateCriteria(criteria);
    //     });

    //     return totalEmployee;
    // }


    function calculatePriorityChildren(data) {
        let totalPriority = 0;

        data?.forEach(keyResult => {
            totalPriority += keyResult.priority || 0;
        });

        return totalPriority;
    }

    function calculateTotalPrioritySum(value) {
        return value?.length > 0 && value.reduce((total, item) => {
            const itemTotalPriority = item.children.reduce((sum, child) => sum + child.priority, 0);
            return total + itemTotalPriority;
        }, 0);
    }

    function calculateTotalPriorityKeyxPoint(data) {
        let totalPriority = 0;

        data?.key_results?.forEach(keyResult => {
            totalPriority += (keyResult.priority * keyResult.point) || 0;
        });

        return totalPriority;
    }


    function calculateTotalPriorityAll(data) {
        let totalEmployee = 0;

        function calculateCriteria(criteria) {
            totalEmployee += criteria.priority || 0;

            if (criteria?.children && criteria?.children?.length > 0) {
                criteria?.children?.forEach(child => {
                    calculateCriteria(child); 
                });
            }
        }

        data?.evaluation_criteria?.forEach(criteria => {
            calculateCriteria(criteria);
        });

        return totalEmployee;
    }

    const [adjustment, setAdjustment] = useState(data?.position_adjustment);
    const [risk, setRisk] = useState(data?.risk);

    const [review, setReview] = useState(data?.manager_comment);
    const [personal, setPersonal] = useState(data?.personal_goal);

    useEffect(() => {
        setAdjustment(data?.position_adjustment)
        setRisk(data?.risk)
        setReview(data?.manager_comment)
        setPersonal(data?.personal_goal)
    }, [data]);

    const columnsNextReview = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: 'center' }}>{index + 1}</div>
                );
            },
        },
        {
            title: 'Mục tiêu cam kết cho Q2',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                return (
                    <InputTarget
                        defaultValue={text}
                        ids={id}
                        record={record}
                        field="name"
                    />
                );
            }
        },
        {
            title: 'Kết quả mong muốn',
            dataIndex: 'desire_result',
            key: 'desire_result',
            render: (text, record, index) => {
                return (
                    <InputTarget
                        defaultValue={text}
                        ids={id}
                        record={record}
                        field="desire_result"
                    />
                );
            }
        },
        {
            title: 'Ngày hoàn thành',
            dataIndex: 'due_date',
            key: 'due_date',
            render: (text, record, index) => {
                return (
                    <InputTarget
                        defaultValue={text}
                        ids={id}
                        record={record}
                        field="due_date" />
                );
            }
        },
    ];

    const onChangeBox = async (e, field) => {
        const params = {
            id: id,
            position_adjustment: field === "adjustment" ? e.target.value : undefined,
            risk: field === "risk" ? e.target.value : undefined
        }
        updateStaffReview(params).then((response) => {
            field === "risk" ? setRisk(e.target.value) : setAdjustment(e.target.value)
        });
    };

    const onReview = useCallback(async (value, field) => {
        field === "manager" ? setReview(value) : setPersonal(value)
        if (inputTimer) {
            clearTimeout(inputTimer);
        }
        inputTimer = setTimeout(async () => {
            const params = {
                id: id,
                manager_comment: field === "manager" ? value : undefined,
                personal_goal: field === "personal" ? value : undefined,
            }
            updateStaffReview(params).then((response) => {
                field === "manager" ? setReview(value) : setPersonal(value)
            });
        }, 500);
    }, [])

    return (
        <div className={className} ref={mySharedRef}>
            <div className='main'>
                <img src={haivan} alt="Haivan" style={{ width: "15%",marginLeft:10 }}></img>
                <div style={{ textAlign: 'center', backgroundColor: '#045083', color: '#ffff', padding: '5px 20px', fontSize: 30, fontWeight: 600, margin: '5px 0',marginLeft:10 }}>ĐÁNH GIÁ NHÂN SỰ {moment(data?.start_date).format("MM/YYYY")}</div>
                <div className='title'>I. THÔNG TIN CHUNG </div>
                <Row gutter={[16, 16]} style={{ fontSize: 16,marginLeft:10 }}>
                    <Col span={10}>
                        <h3>Thông tin nhân sự:</h3>
                        <div className='label_info'>Tên: </div><span style={{ color: 'red', fontWeight: 500 }}>{data?.staff?.name}</span>
                        <br />
                        <div className='label_info'>Mã NV: </div><span style={{ fontWeight: 500 }}>{data?.staff?.code}</span>
                        <br />
                        <div className='label_info'>Vị trí: </div><span style={{ fontWeight: 500 }}>{data?.staff?.position?.cd_name}</span>
                        <br />
                        <div className='label_info'>Cấp bậc: </div><span style={{ fontWeight: 500 }}>{data?.staff?.position?.cd_name}</span>
                        <br />
                        <div className='label_info'>Kỳ đánh giá: </div><span style={{ fontWeight: 500 }}>{moment(data?.start_date).format("DD/MM/YYYY")} - {moment(data?.end_date).format("DD/MM/YYYY")}</span>
                    </Col>
                    <Col span={12}>
                        <h3>Người đánh giá: </h3>
                        <div className='label_info'>Tên: </div><span style={{ fontWeight: 500 }}>{data?.manager?.name}</span>
                        <br />
                        <div className='label_info'>Mã NV:</div><span style={{ fontWeight: 500 }}>{data?.manager?.code}</span>
                        <br />
                        <div className='label_info'>Vị trí: </div><span style={{ fontWeight: 500 }}>{data?.manager?.position?.cd_name}</span>
                        <br />
                    </Col>
                </Row>
                <div className='title'>II. ĐÁNH GIÁ NHÂN SỰ </div>
                <div className="table-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th style={{ minWidth: 60 }}>STT</th>
                                <th style={{ minWidth: 150 }}>Mục tiêu</th>
                                <th style={{ minWidth: 150 }}>Mục tiêu</th>
                                <th style={{ minWidth: 150 }}>Kết quả</th>
                                <th style={{ minWidth: 100 }}>Tỷ lệ (%) kết quả/mục tiêu</th>
                                <th style={{ minWidth: 100 }}>Mức độ ưu tiên(%)</th>
                                <th style={{ minWidth: 100 }}>Tự đánh giá</th>
                                <th style={{ minWidth: 100 }}>Người quản lý trực tiếp đánh giá</th>
                                <th style={{ minWidth: 100 }}>Điểm cuối cùng</th>
                                <th style={{ minWidth: 150 }}>Ghi chú</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr className="section-header">
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>A.</td>
                                <td colSpan="4" style={{ color: '#5781c7', textDecoration: 'underline' }}>ĐÁNH GIÁ HIỆU SUẤT</td>
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>{calculateTotalPriorityKey(data)}</td>
                                <td colSpan="1"></td>
                                <td colSpan="1"></td>
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>{calculateTotalPriorityKeyxPoint(data) / 100}</td>
                                <td colSpan="1"></td>
                            </tr>
                            {data?.key_results?.map((keyResult, index) => {
                                return (
                                    <tr key={keyResult.id}>
                                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                        <td style={{ fontWeight: 600 }}>{keyResult?.key_result?.objective?.code + "-" + keyResult?.key_result?.code + "-" + keyResult.name}</td>
                                        <td>{keyResult.task_target}</td>
                                        <td>{keyResult.task_revenue}</td>
                                        <td style={{ textAlign: 'center' }}>{Number.isNaN((keyResult.task_revenue / keyResult.task_target)) ? "" : ((keyResult.task_revenue / keyResult.task_target) * 100).toFixed()}</td>
                                        <td style={{ textAlign: 'center' }}>{keyResult.priority}</td>
                                        <td style={{ textAlign: 'center' }}>{keyResult.review_employee}</td>
                                        <td style={{ textAlign: 'center' }}>{keyResult.review_manager}</td>
                                        <td style={{ textAlign: 'center' }}>{keyResult.point}</td>
                                        <td>{keyResult.note}</td>
                                    </tr>
                                )

                            })}
                            <tr className="section-header">
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>B.</td>
                                <td colSpan="4" style={{ color: '#5781c7', textDecoration: 'underline' }}>COMPETENCY REVIEW</td>
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>{calculateTotalPrioritySum(data?.evaluation_criteria)}</td>
                                <td colSpan="1"></td>
                                <td colSpan="1"></td>
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>{calculateTotalPriorityAllxKey(data?.evaluation_criteria) / 100}</td>
                                <td colSpan="1"></td>
                            </tr>
                            {data?.evaluation_criteria?.map((criteria, index) => (
                                <React.Fragment key={criteria.id}>
                                    <tr className="section-content">
                                        <td style={{ textAlign: 'center', fontWeight: 650 }}>{`B${index + 1}`}</td>
                                        <td colSpan="4" style={{ fontWeight: 650 }}>{criteria.name}</td>
                                        <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>{calculatePriorityChildren(criteria?.children)}</td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                    </tr>
                                    {criteria.children &&
                                        criteria.children.map((child, idx) => (
                                            <tr key={child.id}>
                                                <td style={{ textAlign: 'center' }}>{`${idx + 1}`}</td>
                                                <td style={{ fontWeight: 650 }}>{child.name}</td>
                                                <td>{child.target}</td>
                                                <td>{child.result}</td>
                                                <td style={{ textAlign: 'center' }}>{Number.isNaN((child.result / child.target)) ? "" : ((child.result / child.target) * 100).toFixed(2)}</td>
                                                <td style={{ textAlign: 'center' }}>{child.priority}</td>
                                                <td style={{ textAlign: 'center' }}>{child.review_employee}</td>
                                                <td style={{ textAlign: 'center' }}>{child.review_manager}</td>
                                                <td style={{ textAlign: 'center' }}>{child.point}</td>
                                                <td >{child.note}</td>
                                            </tr>
                                        ))}
                                </React.Fragment>
                            ))}
                            <tr className="footer">
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>C.</td>
                                <td colSpan="4">ĐIỂM TỔNG</td>
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>100%</td>
                                <td colSpan="1"></td>
                                <td colSpan="1"></td>
                                <td colSpan="1" style={{ fontWeight: 700, textAlign: 'center' }}>{calculateTotalPriorityAllxKey(data?.evaluation_criteria) / 100 + (calculateTotalPriorityKeyxPoint(data) / 100)}</td>
                                <td colSpan="1" style={{ width: 120, textAlign: 'center' }}>Hiệu suất: 70%
                                    <br />Năng lực: 30%
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{marginLeft:10}}>
                    <h3>Ý kiến người quản lý trực tiếp : </h3> {review}
                </div>
                <div style={{ display: 'flex',marginLeft:10 }}>
                    <div style={{ display: 'inline-block', width: 200, fontWeight: 500 }}>Q1: Có điều chỉnh vị trí không?
                        <Radio.Group onChange={(e) => onChangeBox(e, "adjustment")} value={adjustment}>
                            <Radio value={1}>Có</Radio>
                            <Radio value={0}>Không</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ display: 'inline-block', width: 200, fontWeight: 500, marginLeft: 100 }}>Q2: Có rủi ro không?
                        <Radio.Group onChange={(e) => onChangeBox(e, "risk")} value={risk}>
                            <Radio value={1}>Có</Radio>
                            <Radio value={0}>Không</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <br />
                <div className='title'>III. MỤC TIÊU CHO KÌ ĐÁNH GIÁ TIẾP THEO </div>
                <Table dataSource={data?.next_review_targets} columns={columnsNextReview} bordered pagination={false} style={{marginLeft:10}}/>
                <div style={{marginLeft:10}}>
                    <h3>Mục tiêu phát triển bản thân:</h3>{personal}
                </div>
            </div>
        </div>
    )
};
Detail.propTypes = {
    className: PropTypes.any,
};
export default styled(Detail)`
.title {
    background-color: #109CF1;
    color: #ffff;
    text-align: center;
    font-size: 20px;
    padding: 0px 0px !important;
    margin-bottom: 5px;
    margin-left:10px;
}
.label_info {
    font-size: 16px;
    min-width: 200px;
    display: inline-flex;
    font-weight: 500;
    line-height: 30px;
}

// .main {
//     max-height: 100vh;
//     overflow: auto;
//     position: relative;
//     padding-bottom: 100px;
//     max-width: 100vw;
// }

// .table-container {
//     max-height: 600px;
//     max-width: 100%;
//     overflow: auto;
//     border: 1px solid #ddd;
//     font-family: sans-serif;
//     margin-left:10px;
// }

// .styled-table {
//     width: auto;
//     border-collapse: collapse;
//     font-size: 14px;
//     font-family: sans-serif;
//     min-width: 400px;
//     max-width: 100%;
//     box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
// }

.styled-table thead {
    position: sticky;
    top: 0;
    background-color: #002060;
    color: #ffffff;
    z-index: 2;
}
.footer {
    color: #ffffff;
    background-color: #002060;
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
}
.styled-table tbody td {
    border: 1px solid #dddddd;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: auto;
}
.styled-table th,
.styled-table td {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    // text-align: center;
}

.styled-table .section-content {
    background-color: #ededed;
}

.styled-table thead tr {
    background-color: #002060;
    color: #ffffff;
    // text-align: center;
    font-family: serif;
    font-style: italic;
    font-size: 15px;
}

.styled-table th,
.styled-table td {
    padding: 6px 6px;
    border: 1px solid #dddddd;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 6px 6px !important;
    overflow-wrap: break-word;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody td {
    border: 1px solid #dddddd;
    word-wrap: break-word;
}

.section-header td {
    background-color: #f0f0f0;
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
}
`;