const initState = {
  addTask: false,
  addSubTask: false,
  addSection: false,
  activeTask: 0,
  filterComplete: 3,
  filterOptionTask: 1,
  filterGroup:null,
  filterValue: null,
  tasksSeleted: []
};

const actionProject = (actionProject = initState, action) => {
  switch (action.type) {
    case "SET_IS_ADD_TASK":
      return { ...actionProject, addTask: action.status };
    case "SET_IS_ADD_SUBTASK":
      return { ...actionProject, addSubTask: action.status };
    case "SET_IS_ADD_SECTION":
      return { ...actionProject, addSection: action.status };
    case "SET_ACTIVE_TASK":
      return { ...actionProject, activeTask: action.id };
    case "SET_FILTER_COMPLETE_TASK":
      return { ...actionProject, filterComplete: action.option };
    case "SET_FILTER_GROUP":
      return { ...actionProject, filterGroup: action.option, filterValue: action.value };
    case "SET_FILTER_OPTION_TASK":
      return { ...actionProject, filterOptionTask: action.option, filterValue: action.value };
    case "SET_DEFAULT_ACTION_PROJECT":
      return initState;
    case "SET_TASKS_SELECTED":
      return { ...actionProject, tasksSeleted: action.ids};
    default:
      return actionProject;
  }
};
export default actionProject;
