const initalState = {
  file: false,
  document: true,
};
const documentFile = (documentFile = initalState, action) => {
  switch (action.type) {
    case "TOGGLE_FILE_FORMAT":
      return { ...documentFile, file: action.file };
    case "TOGGLE_DOCUMENT_FORMAT":
      return { ...documentFile, document: action.document };
    default:
      return documentFile;
  }
};
export default documentFile;
